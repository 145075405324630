// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/* Display/Heading Fonts */

@font-face {
    font-family: 'alegreyasans-light';
    src: url('../fonts/alegreyasans-light.woff2') format('woff2'), url('../fonts/alegreyasans-light.woff') format('woff');
    font-weight: normal; // Font-weight = 300
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-regular';
    src: url('../fonts/alegreyasans-regular.woff2') format('woff2'), url('../fonts/alegreyasans-regular.woff') format('woff');
    font-weight: normal; // Font-weight = 400
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-medium';
    src: url('../fonts/alegreyasans-medium.woff2') format('woff2'), url('../fonts/alegreyasans-medium.woff') format('woff');
    font-weight: normal; // Font-weight = 500
    font-style: normal;
}

/*@font-face {
    font-family: 'alegreyasans-semibold';
    src: url('../fonts/alegreyasans-semibold.woff2') format('woff2'), url('../fonts/alegreyasans-semibold.woff') format('woff');
    font-weight: normal; // Font-weight = 600
    font-style: normal;
}*/

@font-face {
    font-family: 'alegreyasans-bold';
    src: url('../fonts/alegreyasans-bold.woff2') format('woff2'), url('../fonts/alegreyasans-bold.woff') format('woff');
    font-weight: normal; // Font-weight = 700
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-extrabold';
    src: url('../fonts/alegreyasans-extrabold.woff2') format('woff2'), url('../fonts/alegreyasans-extrabold.woff') format('woff');
    font-weight: normal; // Font-weight = 800
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-black';
    src: url('../fonts/alegreyasans-black.woff2') format('woff2'), url('../fonts/alegreyasans-black.woff') format('woff');
    font-weight: normal; // Font-weight = 900
    font-style: normal;
}




/* Body Fonts */
/*
@font-face {
    font-family: 'alegreyasans-light';
    src: url('../fonts/alegreyasans-light.woff2') format('woff2'), url('../fonts/alegreyasans-light.woff') format('woff');
    font-weight: normal; // Font-weight = 300
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-regular';
    src: url('../fonts/alegreyasans-regular.woff2') format('woff2'), url('../fonts/alegreyasans-regular.woff') format('woff');
    font-weight: normal; // Font-weight = 400
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-medium';
    src: url('../fonts/alegreyasans-medium.woff2') format('woff2'), url('../fonts/alegreyasans-medium.woff') format('woff');
    font-weight: normal; // Font-weight = 500
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-semibold';
    src: url('../fonts/alegreyasans-semibold.woff2') format('woff2'), url('../fonts/alegreyasans-semibold.woff') format('woff');
    font-weight: normal; // Font-weight = 600
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-bold';
    src: url('../fonts/alegreyasans-bold.woff2') format('woff2'), url('../fonts/alegreyasans-bold.woff') format('woff');
    font-weight: normal; // Font-weight = 700
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-extrabold';
    src: url('../fonts/alegreyasans-extrabold.woff2') format('woff2'), url('../fonts/alegreyasans-extrabold.woff') format('woff');
    font-weight: normal; // Font-weight = 800
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-black';
    src: url('../fonts/alegreyasans-black.woff2') format('woff2'), url('../fonts/alegreyasans-black.woff') format('woff');
    font-weight: normal; // Font-weight = 900
    font-style: normal;
}
*/



