// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/* ------------ Colors Variables -------------*/

// Neutrals
$white-color-ev: #ffffff;
$text-color-ev: #262e3d;
$light-gray-color-ev: #f3f3f3;
$mid-gray-color-ev: #8991a0;
$dark-gray-color-ev: #1a202a;
$separator-color-ev: #cdcdcd;



// Alert Colors
$error-color-ev: #d41010;
$success-color-ev: #0d920d;



// Brand Colors
$base-color-ev: #610c04;
$dark-base-color-ev: #260101;

$secondary-color-ev: #260101;
$dark-secondary-color-ev: #110000;

$accent-color-ev: #f68019;
$dark-accent-color-ev: #ff671f;


/* ------------ Space Variables -------------*/

$gutter: 30px;




/* ------------ Fonts Variables -------------*/

// Heading Fonts
$heading-font-light: 'alegreyasans-light', Helvetica, Arial, sans-serif !important;
$heading-font-regular: 'alegreyasans-regular', Helvetica, Arial, sans-serif !important;
$heading-font-medium: 'alegreyasans-medium', Helvetica, Arial, sans-serif !important;
//$heading-font-semibold: 'alegreyasans-semibold', Helvetica, Arial, sans-serif !important;
$heading-font-bold: 'alegreyasans-bold', Helvetica, Arial, sans-serif !important;
$heading-font-extrabold: 'alegreyasans-extrabold', Helvetica, Arial, sans-serif !important;
$heading-font-black: 'alegreyasans-black', Helvetica, Arial, sans-serif !important;


// Body Fonts
$body-font-light: 'alegreyasans-light', Helvetica, Arial, sans-serif !important;
$body-font-regular: 'alegreyasans-regular', Helvetica, Arial, sans-serif !important;
$body-font-medium: 'alegreyasans-medium', Helvetica, Arial, sans-serif !important;
//$body-font-semibold: 'alegreyasans-semibold', Helvetica, Arial, sans-serif !important;
$body-font-bold: 'alegreyasans-bold', Helvetica, Arial, sans-serif !important;
$body-font-extrabold: 'alegreyasans-extrabold', Helvetica, Arial, sans-serif !important;
$body-font-black: 'alegreyasans-black', Helvetica, Arial, sans-serif !important;


$button-font: $body-font-medium;

