header{
    background-color: $dark-gray-color-ev;
    height: 50px;

    /*.nav-wrapper {
        max-width: 1170px;
        margin: 0 auto;
    }*/
}

.top-nav {
    //@include box-shadow(1px 1px 5px $dark-gray-color-ev);
    @include display-flex(flex-start, center);
    position: relative;
    color: $white-color-ev;
    border: none;
    transition: 0.5s;
    overflow: visible;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 50px;
}

.top-nav-header {
    @include display-flex(space-between, center);
    margin: 0;
    width: 100%;
    line-height: 50px;
}

.nav-logo {
    padding: 0 10px 0 10px;
    margin: 0;
    width: 200px;
    height: 50px;
    overflow: hidden;
    max-width: 200px;
    color: $accent-color-ev;
    font-size: 20px;


    &:hover {
        color: lighten($base-color-ev, 5%);
    }
    .logo-icon{
        margin-right: 5px;
        padding-left: 0.5rem;
        img {
            max-width: 25px;
            width: 25px !important;
            margin: 0 auto;
        }
    }
    .logo-text{
        color: $accent-color-ev;
        margin-left: 0.5rem;
        img {
            max-width: 100px;
            width: 100px !important;
            margin: 0 auto;
        }
        &:hover {
            color: lighten($dark-accent-color-ev, 5%);
        }
    }
}

.nav-hamburger {
    border: none;
    padding: 7px 20px 7px;
    background: none;
    outline: none;
    margin-right: 10px;
    line-height: 30px;
    .mdi {
        color: $white-color-ev;
        font-size: 20px;
    }
    &:hover {
        outline: none;
        box-shadow: none;
        background-color: $base-color-ev;
        color: $white-color-ev;
        i.mdi {
            color: $white-color-ev;
        }
    }
}

.top-navbar-expanded {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50px;
    right: 0;
    left: 260px;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    z-index: 1000;
}

.sidebar-menu-hamburger{
    display: none;
}

.expanded-top-navbar-ul {
    margin: 0;
    padding: 0;
    display: block;
}

.top-nav-item {
    text-align: left;
}

.top-nav-link {
    padding: 1rem 2rem;
    display: block;
    font-size: 1.0em;
    font-family: $body-font-regular;
    line-height: 1;
    z-index: 1000;
    cursor: pointer;
    &:hover {
        color: $white-color-ev;
    }

    &:active,
    &:focus {
        color: $white-color-ev;
        background-color: $base-color-ev;
    }

    &.selected{
        color: $white-color-ev;
        background-color: $base-color-ev;
    }


    &.nav-dropdown-header {
        color: $dark-gray-color-ev;
    }
    img {
        width: 30px;
    }
    .mdi {
        font-size: 20px;
    }
}

.toplevel-top-nav-item{
    border-bottom: 1px solid #999999;
    color: $mid-gray-color-ev;
    background-color: $dark-gray-color-ev;

    &:active,
    &:focus {
        color: $white-color-ev;
        background-color: $base-color-ev;
    }

    &.selected{
        color: $white-color-ev;
        background-color: $base-color-ev;
    }

    .menu-name {
        padding-left: 0.5rem;
    }

}



.top-nav-dropdown-ul{
    color: $white-color-ev;
    background-color: lighten($dark-gray-color-ev, 5%);
    max-height: 0;
    transition: max-height 0.1s ease-out;
    overflow: hidden;
}

.top-nav-dropdown-link{
    color: $mid-gray-color-ev;

    .mdi {
        padding-right: 0.5rem;
    }

    &:active,
    &:focus {
        color: $white-color-ev;
    }
}



@media screen and (min-width: 992px) {
    .top-nav-header {
        display: flex;
        width: 197px;
        flex-basis: 197px;
        .nav-hamburger {
            display: none;
        }
    }

    .top-navbar-expanded {
        position: relative;
        top: 0;
        left: 0;
        @include display-flex(space-between, center);
        flex-grow: 1;
        max-height: 50px;
        overflow: visible;
    }

    .sidebar-menu-hamburger{
        display: block;
        //padding: 0 1rem;

        a {
            display: block;
        }
        .mdi {
            color: $white-color-ev;
            font-size: 20px;
        }
    }

    .expanded-top-navbar-ul {
        display: flex;
    }

    .top-nav-link {
        padding: 1rem 1rem;
        img {
            width: 26px;
        }
        .mdi {
            font-size: 22px;
        }
    }

    .toplevel-top-nav-item{
        border-bottom: none;
        padding: 0 1rem;
        line-height: 45px;
        .menu-name {
            display: none;
        }
    }




    .top-nav-dropdown-ul{
        position: absolute;
        top: 50px;
        max-height: 0;
        transition: max-height 0.1s ease-out;
        overflow: hidden;
        z-index: 1000;


        &.right {
            left: -170px;
            right: 0;
        }

        &.opened {
            .top-nav-dropdown-link{
                padding: 1rem 1rem;
                /*&:last-of-type {
                    padding-bottom: 2rem;
                }*/
            }

            li:last-of-type {
                a {
                    padding-bottom: 2rem;
                }
            }
        }
    }

}


@media screen and (min-width: 1366px) {
    .toplevel-top-nav-item{
        .menu-name {
            display: inline-block;
        }
    }
}
