/**
 * Basic typography style for copy text
 */

body {
    font-family: $body-font-regular;
    font-size: 16px;
    color: $text-color-ev;
    line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font-medium;
}

p {
    padding: 0.5rem 0;
}

@media screen and (min-width: 1441px) {
    h1 {
        font-size: 3.2rem;
        line-height: 1.3;
    }
    h2 {
        font-size: 2.1rem;
        line-height: 1.3;
    }
    h3 {
        font-size: 1.8rem;
        line-height: 1.3;
    }
    h4 {
        font-size: 1.6rem;
        line-height: 1.4;
    }
    h5 {
        font-size: 1.4rem;
        line-height: 1.4;
    }
    p {
        font-size: 1.1rem;
        line-height: 1.5;
    }
    small {
        font-size: 0.95rem;
        line-height: 1.5;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
    h1 {
        font-size: 2.9rem;
        line-height: 1.3;
    }
    h2 {
        font-size: 2rem;
        line-height: 1.3;
    }
    h3 {
        font-size: 1.7rem;
        line-height: 1.3;
    }
    h4 {
        font-size: 1.5rem;
        line-height: 1.4;
    }
    h5 {
        font-size: 1.3rem;
        line-height: 1.5;
    }
    p {
        font-size: 1rem;
        line-height: 1.6;
    }
    small {
        font-size: 0.9rem;
        line-height: 1.5;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    h1 {
        font-size: 2.6rem;
        line-height: 1.3;
    }
    h2 {
        font-size: 1.9rem;
        line-height: 1.3;
    }
    h3 {
        font-size: 1.6rem;
        line-height: 1.4;
    }
    h4 {
        font-size: 1.4rem;
        line-height: 1.4;
    }
    h5 {
        font-size: 1.2rem;
        line-height: 1.5;
    }
    p {
        font-size: 1rem;
        line-height: 1.5;
    }
    small {
        font-size: 0.9rem;
        line-height: 1.5;
    }
}

@media screen and (min-width: 421px) and (max-width: 768px) {
    h1 {
        font-size: 2.4rem;
        line-height: 1.3;
    }
    h2 {
        font-size: 1.8rem;
        line-height: 1.4;
    }
    h3 {
        font-size: 1.5rem;
        line-height: 1.4;
    }
    h4 {
        font-size: 1.3rem;
        line-height: 1.5;
    }
    h5 {
        font-size: 1.15rem;
        line-height: 1.5;
    }
    p {
        font-size: 1rem;
        line-height: 1.5;
    }
    .small-text {
        font-size: 0.9rem;
        line-height: 1.5;
    }
}

@media screen and (max-width: 420px) {
    h1 {
        font-size: 2.2rem;
        line-height: 1.4;
    }
    h2 {
        font-size: 1.7rem;
        line-height: 1.4;
    }
    h3 {
        font-size: 1.4rem;
        line-height: 1.4;
    }
    h4 {
        font-size: 1.2rem;
        line-height: 1.4;
    }
    h5 {
        font-size: 1rem;
        line-height: 1.4;
    }
    p {
        font-size: 0.95rem;
        line-height: 1.5;
    }
    small {
        font-size: 0.85rem;
        line-height: 1.5;
    }
}

