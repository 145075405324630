// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
div.top-header-info {
    background-color: $dark-base-color-ev;
    width: 100%;
    display: block;
    padding: 5px;
    .content-wrapper {
        padding: 0;
        display: block;
        text-align: center;
    }
    p {
        color: $white-color-ev;
        margin-right: 0;
        i {
            color: $accent-color-ev;
            margin-right: 5px;
        }
    }
}

.header_image {
    display: block;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    max-width: 1280px;
    img {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
}

div.after-head {
    margin-top: 50px;
    display: block;
}

@media screen and (min-width: 768px) {
    div.top-header-info {
        .content-wrapper {
            @include display-flex(flex-end, center);
        }
        p {
            margin-right: 40px;
            i {
                margin-right: 7px;
            }
        }
    }
}
