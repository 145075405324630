// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@mixin animate ( $attr, $time ) {
    -webkit-transition: $attr $time ease;
    -moz-transition: $attr $time ease;
    -ms-transition: $attr $time ease;
    -o-transition: $attr $time ease;
    transition: $attr $time ease;
}

@mixin transform ( $val ) {
    -webkit-transform: $val;
    -moz-transform: $val;
    -ms-transform: $val;
    -o-transform: $val;
    transform: $val;
}

@mixin linear-gradient($startColor, $stopColor) {
    background: $startColor; /* Old browsers */
    background: -moz-linear-gradient(top, $startColor 0%, $stopColor 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, $startColor 0%,$stopColor 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, $startColor 0%,$stopColor 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$startColor', endColorstr='$stopColor',GradientType=0 ); /* IE6-9 */
}

@mixin bg-overlay($rgba, $url) {
    background: linear-gradient($rgba, $rgba), url($url);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

@mixin box-shadow($value) {
    -webkit-box-shadow: $value;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    $value;  /* Firefox 3.5 - 3.6 */
    box-shadow:         $value;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }

@mixin text-shadow($value) {
-webkit-text-shadow: $value;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
-moz-text-shadow:    $value;  /* Firefox 3.5 - 3.6 */
text-shadow:         $value;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

@mixin display-flex ( $justify, $align ) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}
