/* Padding All */
.pd-0 { padding: 0; }
.pd-0_25 { padding: 0.25rem; }
.pd-0_5 { padding: 0.5rem; }
.pd-0_75 { padding: 0.75rem; }
.pd-1 { padding: 1rem; }
.pd-1_25 { padding: 1.25rem; }
.pd-1_5 { padding: 1.5rem; }
.pd-1_75 { padding: 1.75rem; }
.pd-2 { padding: 2rem; }
.pd-2_25 { padding: 2.25rem; }
.pd-2_5 { padding: 2.5rem; }
.pd-2_75 { padding: 2.75rem; }
.pd-3 { padding: 3rem; }
.pd-3_25 { padding: 3.25rem; }
.pd-3_5 { padding: 3.5rem;}
.pd-3_75 { padding: 3.75rem;}
.pd-4 { padding: 4rem;}
.pd-4_25 { padding: 4.25rem; }
.pd-4_5 { padding: 4.5rem; }
.pd-4_75 { padding: 4.75rem; }
.pd-5 { padding: 5rem; }
.pd-5_25 { padding: 5.25rem; }
.pd-5_5 { padding: 5.5rem; }
.pd-5_75 { padding: 5.75rem; }
.pd-6 { padding: 6rem; }
.pd-6_25 { padding: 6.25rem; }
.pd-6_5 { padding: 6.5rem; }
.pd-6_75 { padding: 6.75rem; }
.pd-7 { padding: 7rem; }
.pd-7_25 { padding: 7.25rem; }
.pd-7_5 { padding: 7.5rem; }
.pd-7_75 { padding: 7.75rem; }
.pd-8 { padding: 8rem; }
.pd-8_25 { padding: 8.25rem; }
.pd-8_5 { padding: 8.5rem; }
.pd-8_75 { padding: 8.75rem; }
.pd-9 { padding: 9rem; }
.pd-9_25 { padding: 9.25rem; }
.pd-9_5 { padding: 9.5rem; }
.pd-9_75 { padding: 9.75rem; }
.pd-10 { padding: 10rem; }



/* Padding Vertical */
.pd-y-0_25 { padding-top: 0.25rem; padding-bottom: 0.25rem;}
.pd-y-0_5 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.pd-y-0_75 { padding-top: 0.75rem; padding-bottom: 0.75rem; }
.pd-y-1 { padding-top: 1rem; padding-bottom: 1rem; }
.pd-y-1_25 { padding-top: 1.25rem; padding-bottom: 1.25rem; }
.pd-y-1_5 { padding-top: 1.5rem;  padding-bottom: 1.5rem; }
.pd-y-1_75 { padding-top: 1.75rem; padding-bottom: 1.75rem; }
.pd-y-2 { padding-top: 2rem; padding-bottom: 2rem; }
.pd-y-2_25 { padding-top: 2.25rem; padding-bottom: 2.25rem; }
.pd-y-2_5 { padding-top: 2.5rem;  padding-bottom: 2.5rem; }
.pd-y-2_75 { padding-top: 2.75rem; padding-bottom: 2.75rem; }
.pd-y-3 { padding-top: 3rem; padding-bottom: 3rem; }
.pd-y-3_25 { padding-top: 3.25rem; padding-bottom: 3.25rem; }
.pd-y-3_5 { padding-top: 3.5rem;  padding-bottom: 3.5rem; }
.pd-y-3_75 { padding-top: 3.75rem; padding-bottom: 3.75rem;}
.pd-y-4 { padding-top: 4rem; padding-bottom: 4rem; }
.pd-y-4_25 { padding-top: 4.25rem; padding-bottom: 4.25rem; }
.pd-y-4_5 { padding-top: 4.5rem;  padding-bottom: 4.5rem; }
.pd-y-4_75 { padding-top: 4.75rem; padding-bottom: 4.75rem; }
.pd-y-5 { padding-top: 5rem; padding-bottom: 5rem; }
.pd-y-5_25 { padding-top: 5.25rem; padding-bottom: 5.25rem; }
.pd-y-5_5 { padding-top: 5.5rem;  padding-bottom: 5.5rem; }
.pd-y-5_75 { padding-top: 5.75rem; padding-bottom: 5.75rem; }
.pd-y-6 { padding-top: 6rem; padding-bottom: 6rem; }
.pd-y-6_25 { padding-top: 6.25rem; padding-bottom: 6.25rem; }
.pd-y-6_5 { padding-top: 6.5rem; padding-bottom: 6.5rem; }
.pd-y-6_75 { padding-top: 6.75rem; padding-bottom: 6.75rem; }
.pd-y-7 { padding-top: 7rem; padding-bottom: 7rem; }
.pd-y-7_25 { padding-top: 7.25rem; padding-bottom: 7.25rem; }
.pd-y-7_5 { padding-top: 7.5rem;  padding-bottom: 7.5rem; }
.pd-y-7_75 { padding-top: 7.75rem; padding-bottom: 7.75rem; }
.pd-y-8 { padding-top: 8rem; padding-bottom: 8rem; }
.pd-y-8_25 { padding-top: 8.25rem; padding-bottom: 8.25rem; }
.pd-y-8_5 { padding-top: 8.5rem;  padding-bottom: 8.5rem; }
.pd-y-8_75 { padding-top: 8.75rem; padding-bottom: 8.75rem; }
.pd-y-9 { padding-top: 9rem; padding-bottom: 9rem; }
.pd-y-9_25 { padding-top: 9.25rem; padding-bottom: 9.25rem; }
.pd-y-9_5 { padding-top: 9.5rem; padding-bottom: 9.5rem; }
.pd-y-9_75 { padding-top: 9.75rem; padding-bottom: 9.75rem; }
.pd-y-10 { padding-top: 10rem; padding-bottom: 10rem; }



/* Padding Horizontal */
.pd-x-0_25 { padding-left: 0.25rem; padding-right: 0.25rem; }
.pd-x-0_5 { padding-left: 0.5rem; padding-right: 0.5rem;}
.pd-x-0_75 { padding-left: 0.75rem; padding-right: 0.75rem; }
.pd-x-1 { padding-left: 1rem; padding-right: 1rem; }
.pd-x-1_25 { padding-left: 1.25rem; padding-right: 1.25rem; }
.pd-x-1_5 { padding-left: 1.5rem;  padding-right: 1.5rem; }
.pd-x-1_75 { padding-left: 1.75rem; padding-right: 1.75rem; }
.pd-x-2 { padding-left: 2rem; padding-right: 2rem; }
.pd-x-2_25 { padding-left: 2.25rem; padding-right: 2.25rem; }
.pd-x-2_5 { padding-left: 2.5rem; padding-right: 2.5rem; }
.pd-x-2_75 { padding-left: 2.75rem; padding-right: 2.75rem; }
.pd-x-3 { padding-left: 3rem; padding-right: 3rem; }
.pd-x-3_25 { padding-left: 3.25rem; padding-right: 3.25rem; }
.pd-x-3_5 { padding-left: 3.5rem; padding-right: 3.5rem; }
.pd-x-3_75 { padding-left: 3.75rem; padding-right: 3.75rem; }
.pd-x-4 { padding-left: 4rem; padding-right: 4rem; }
.pd-x-4_25 { padding-left: 4.25rem; padding-right: 4.25rem; }
.pd-x-4_5 { padding-left: 4.5rem; padding-right: 4.5rem; }
.pd-x-4_75 { padding-left: 4.75rem; padding-right: 4.75rem; }
.pd-x-5 { padding-left: 5rem; padding-right: 5rem; }
.pd-x-5_25 { padding-left: 5.25rem; padding-right: 5.25rem; }
.pd-x-5_5 { padding-left: 5.5rem; padding-right: 5.5rem; }
.pd-x-5_75 { padding-left: 5.75rem; padding-right: 5.75rem; }
.pd-x-6 { padding-left: 6rem; padding-right: 6rem; }
.pd-x-6_25 { padding-left: 6.25rem; padding-right: 6.25rem; }
.pd-x-6_5 { padding-left: 6.5rem; padding-right: 6.5rem; }
.pd-x-6_75 { padding-left: 6.75rem; padding-right: 6.75rem; }
.pd-x-7 { padding-left: 7rem; padding-right: 7rem; }
.pd-x-7_25 { padding-left: 7.25rem; padding-right: 7.25rem; }
.pd-x-7_5 { padding-left: 7.5rem; padding-right: 7.5rem; }
.pd-x-7_75 { padding-left: 7.75rem; padding-right: 7.75rem; }
.pd-x-8 { padding-left: 8rem; padding-right: 8rem; }
.pd-x-8_25 { padding-left: 8.25rem; padding-right: 8.25rem; }
.pd-x-8_5 { padding-left: 8.5rem; padding-right: 8.5rem; }
.pd-x-8_75 { padding-left: 8.75rem; padding-right: 8.75rem; }
.pd-x-9 { padding-left: 9rem; padding-right: 9rem; }
.pd-x-9_25 { padding-left: 9.25rem; padding-right: 9.25rem; }
.pd-x-9_5 { padding-left: 9.5rem; padding-right: 9.5rem; }
.pd-x-9_75 { padding-left: 9.75rem; padding-right: 9.75rem; }
.pd-x-10 { padding-left: 10rem; padding-right: 10rem; }



/* Padding Top */
.pd-t-0_25 { padding-top: 0.25rem; }
.pd-t-0_5 { padding-top: 0.5rem; }
.pd-t-0_75 { padding-top: 0.75rem; }
.pd-t-1 { padding-top: 1rem; }
.pd-t-1_25 { padding-top: 1.25rem; }
.pd-t-1_5 { padding-top: 1.5rem; }
.pd-t-1_75 { padding-top: 1.75rem; }
.pd-t-2 { padding-top: 2rem; }
.pd-t-2_25 { padding-top: 2.25rem; }
.pd-t-2_5 { padding-top: 2.5rem; }
.pd-t-2_75 { padding-top: 2.75rem; }
.pd-t-3 { padding-top: 3rem; }
.pd-t-3_25 { padding-top: 3.25rem; }
.pd-t-3_5 { padding-top: 3.5rem; }
.pd-t-3_75 { padding-top: 3.75rem;}
.pd-t-4 { padding-top: 4rem; }
.pd-t-4_25 { padding-top: 4.25rem; }
.pd-t-4_5 { padding-top: 4.5rem; }
.pd-t-4_75 { padding-top: 4.75rem; }
.pd-t-5 { padding-top: 5rem; }
.pd-t-5_25 { padding-top: 5.25rem; }
.pd-t-5_5 { padding-top: 5.5rem; }
.pd-t-5_75 { padding-top: 5.75rem; }
.pd-t-6 { padding-top: 6rem; }
.pd-t-6_25 { padding-top: 6.25rem; }
.pd-t-6_5 { padding-top: 6.5rem; }
.pd-t-6_75 { padding-top: 6.75rem; }
.pd-t-7 { padding-top: 7rem; }
.pd-t-7_25 { padding-top: 7.25rem; }
.pd-t-7_5 { padding-top: 7.5rem; }
.pd-t-7_75 { padding-top: 7.75rem; }
.pd-t-8 { padding-top: 8rem; }
.pd-t-8_25 { padding-top: 8.25rem; }
.pd-t-8_5 { padding-top: 8.5rem; }
.pd-t-8_75 { padding-top: 8.75rem; }
.pd-t-9 { padding-top: 9rem; }
.pd-t-9_25 { padding-top: 9.25rem; }
.pd-t-9_5 { padding-top: 9.5rem; }
.pd-t-9_75 { padding-top: 9.75rem; }
.pd-t-10 { padding-top: 10rem; }





/* Padding Right */
.pd-r-0_25 { padding-right: 0.25rem; }
.pd-r-0_5 { padding-right: 0.5rem; }
.pd-r-0_75 { padding-right: 0.75rem; }
.pd-r-1 { padding-right: 1rem; }
.pd-r-1_25 { padding-right: 1.25rem; }
.pd-r-1_5 { padding-right: 1.5rem; }
.pd-r-1_75 { padding-right: 1.75rem; }
.pd-r-2 { padding-right: 2rem; }
.pd-r-2_25 { padding-right: 2.25rem; }
.pd-r-2_5 { padding-right: 2.5rem; }
.pd-r-2_75 { padding-right: 2.75rem; }
.pd-r-3 { padding-right: 3rem; }
.pd-r-3_25 { padding-right: 3.25rem; }
.pd-r-3_5 { padding-right: 3.5rem; }
.pd-r-3_75 { padding-right: 3.75rem;}
.pd-r-4 { padding-right: 4rem; }
.pd-r-4_25 { padding-right: 4.25rem; }
.pd-r-4_5 { padding-right: 4.5rem; }
.pd-r-4_75 { padding-right: 4.75rem; }
.pd-r-5 { padding-right: 5rem; }
.pd-r-5_25 { padding-right: 5.25rem; }
.pd-r-5_5 { padding-right: 5.5rem; }
.pd-r-5_75 { padding-right: 5.75rem; }
.pd-r-6 { padding-right: 6rem; }
.pd-r-6_25 { padding-right: 6.25rem; }
.pd-r-6_5 { padding-right: 6.5rem; }
.pd-r-6_75 { padding-right: 6.75rem; }
.pd-r-7 { padding-right: 7rem; }
.pd-r-7_25 { padding-right: 7.25rem; }
.pd-r-7_5 { padding-right: 7.5rem; }
.pd-r-7_75 { padding-right: 7.75rem; }
.pd-r-8 { padding-right: 8rem; }
.pd-r-8_25 { padding-right: 8.25rem; }
.pd-r-8_5 { padding-right: 8.5rem; }
.pd-r-8_75 { padding-right: 8.75rem; }
.pd-r-9 { padding-right: 9rem; }
.pd-r-9_25 { padding-right: 9.25rem; }
.pd-r-9_5 { padding-right: 9.5rem; }
.pd-r-9_75 { padding-right: 9.75rem; }
.pd-r-10 { padding-right: 10rem; }




/* Padding Bottom */
.pd-b-0_25 { padding-bottom: 0.25rem; }
.pd-b-0_5 { padding-bottom: 0.5rem; }
.pd-b-0_75 { padding-bottom: 0.75rem; }
.pd-b-1 { padding-bottom: 1rem; }
.pd-b-1_25 { padding-bottom: 1.25rem; }
.pd-b-1_5 { padding-bottom: 1.5rem; }
.pd-b-1_75 { padding-bottom: 1.75rem; }
.pd-b-2 { padding-bottom: 2rem; }
.pd-b-2_25 { padding-bottom: 2.25rem; }
.pd-b-2_5 { padding-bottom: 2.5rem; }
.pd-b-2_75 { padding-bottom: 2.75rem; }
.pd-b-3 { padding-bottom: 3rem; }
.pd-b-3_25 { padding-bottom: 3.25rem; }
.pd-b-3_5 { padding-bottom: 3.5rem; }
.pd-b-3_75 { padding-bottom: 3.75rem;}
.pd-b-4 { padding-bottom: 4rem; }
.pd-b-4_25 { padding-bottom: 4.25rem; }
.pd-b-4_5 { padding-bottom: 4.5rem; }
.pd-b-4_75 { padding-bottom: 4.75rem; }
.pd-b-5 { padding-bottom: 5rem; }
.pd-b-5_25 { padding-bottom: 5.25rem; }
.pd-b-5_5 { padding-bottom: 5.5rem; }
.pd-b-5_75 { padding-bottom: 5.75rem; }
.pd-b-6 { padding-bottom: 6rem; }
.pd-b-6_25 { padding-bottom: 6.25rem; }
.pd-b-6_5 { padding-bottom: 6.5rem; }
.pd-b-6_75 { padding-bottom: 6.75rem; }
.pd-b-7 { padding-bottom: 7rem; }
.pd-b-7_25 { padding-bottom: 7.25rem; }
.pd-b-7_5 { padding-bottom: 7.5rem; }
.pd-b-7_75 { padding-bottom: 7.75rem; }
.pd-b-8 { padding-bottom: 8rem; }
.pd-b-8_25 { padding-bottom: 8.25rem; }
.pd-b-8_5 { padding-bottom: 8.5rem; }
.pd-b-8_75 { padding-bottom: 8.75rem; }
.pd-b-9 { padding-bottom: 9rem; }
.pd-b-9_25 { padding-bottom: 9.25rem; }
.pd-b-9_5 { padding-bottom: 9.5rem; }
.pd-b-9_75 { padding-bottom: 9.75rem; }
.pd-b-10 { padding-bottom: 10rem; }



/* Padding Left */
.pd-l-0_25 { padding-left: 0.25rem; }
.pd-l-0_5 { padding-left: 0.5rem; }
.pd-l-0_75 { padding-left: 0.75rem; }
.pd-l-1 { padding-left: 1rem; }
.pd-l-1_25 { padding-left: 1.25rem; }
.pd-l-1_5 { padding-left: 1.5rem; }
.pd-l-1_75 { padding-left: 1.75rem; }
.pd-l-2 { padding-left: 2rem; }
.pd-l-2_25 { padding-left: 2.25rem; }
.pd-l-2_5 { padding-left: 2.5rem; }
.pd-l-2_75 { padding-left: 2.75rem; }
.pd-l-3 { padding-left: 3rem; }
.pd-l-3_25 { padding-left: 3.25rem; }
.pd-l-3_5 { padding-left: 3.5rem; }
.pd-l-3_75 { padding-left: 3.75rem;}
.pd-l-4 { padding-left: 4rem; }
.pd-l-4_25 { padding-left: 4.25rem; }
.pd-l-4_5 { padding-left: 4.5rem; }
.pd-l-4_75 { padding-left: 4.75rem; }
.pd-l-5 { padding-left: 5rem; }
.pd-l-5_25 { padding-left: 5.25rem; }
.pd-l-5_5 { padding-left: 5.5rem; }
.pd-l-5_75 { padding-left: 5.75rem; }
.pd-l-6 { padding-left: 6rem; }
.pd-l-6_25 { padding-left: 6.25rem; }
.pd-l-6_5 { padding-left: 6.5rem; }
.pd-l-6_75 { padding-left: 6.75rem; }
.pd-l-7 { padding-left: 7rem; }
.pd-l-7_25 { padding-left: 7.25rem; }
.pd-l-7_5 { padding-left: 7.5rem; }
.pd-l-7_75 { padding-left: 7.75rem; }
.pd-l-8 { padding-left: 8rem; }
.pd-l-8_25 { padding-left: 8.25rem; }
.pd-l-8_5 { padding-left: 8.5rem; }
.pd-l-8_75 { padding-left: 8.75rem; }
.pd-l-9 { padding-left: 9rem; }
.pd-l-9_25 { padding-left: 9.25rem; }
.pd-l-9_5 { padding-left: 9.5rem; }
.pd-l-9_75 { padding-left: 9.75rem; }
.pd-l-10 { padding-left: 10rem; }
