/* The container must be positioned relative: */
.elabs-form-select-wrap {
    position: relative;
}

.elabs-form-select-wrap select {
    display: none; /*hide original SELECT element: */
}

.select-selected {
    background-color: $white-color-ev;
    //margin-bottom: 2rem;
}

/* Style the arrow inside the select element: */
.select-selected:after {
    position: absolute;
    content: "";
    top: 20px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: $dark-gray-color-ev transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    border-color: transparent transparent $dark-gray-color-ev transparent;
    top: 12px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
    color: $dark-gray-color-ev;

    cursor: pointer;
}

.select-selected {
    border: 1px solid $mid-gray-color-ev;
    padding: 0.45rem 0.5rem;
    border-radius: 3px;
}

.select-items div {
    padding: 0.3rem 0.5rem;
    background-color: $white-color-ev;
    border-radius: 0;
}

/* Style items (options): */
.select-items {
    position: absolute;
    border: 1px solid #f9f9f9;
    border-radius: 8px;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    max-height: 180px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 0 3px 10px rgba(0,0,0,0.2);

    &.mid-viewport {
        top: unset;
        bottom: 100%;
    }
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover {
    background-color: lighten($base-color-ev, 10%);
    color: $white-color-ev;
}

.same-as-selected {
    background-color: $base-color-ev !important;
    color: $white-color-ev !important;

    &:hover {
        background-color: $base-color-ev !important;
        color: $white-color-ev !important;
    }
}















/*

.s-hidden {
    visibility:hidden;
    padding-right:10px;
}
.select {
    cursor:pointer;
    display:inline-block;
    position:relative;
    font:normal 11px/22px Arial, Sans-Serif;
    color:black;
    border:1px solid #ccc;
}
.styledSelect {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:white;
    padding:0 10px;
    font-weight:bold;
}
.styledSelect:after {
    content:"";
    width:0;
    height:0;
    //border:5px solid transparent;
    border-color:black transparent transparent transparent;
    position:absolute;
    top:9px;
    right:6px;
}
.styledSelect:active, .styledSelect.active {
    background-color:#eee;
}
.options {
    display:none;
    position:absolute;
    top:100%;
    right:0;
    left:0;
    z-index:999;
    margin:0 0;
    padding:0 0;
    list-style:none;
    border:1px solid #ccc;
    background-color:white;
    -webkit-box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
}
.options li {
    //padding:0 6px;
    margin:0 0;
    padding:0.5rem 10px;
}
.options li:hover {
    background-color:$base;
    color:white;
}
*/



