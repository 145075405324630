// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.button {
    border: 1px solid $base-color-ev;
    border-radius: 8px;
    padding: 7px 1.5rem;
    font-family: $button-font;
    font-size: 1.2em;
    color: $base-color-ev;
    display: inline-block;
    cursor: pointer;
    text-align: center;

    &:hover {
        color: $white-color-ev;
        background: $base-color-ev;
        text-decoration: none;
    }
}

.white-outline-button {
    border: 1px solid $white-color-ev;
    color: $white-color-ev !important;
    background: none;

    &:hover {
        color: $base-color-ev !important;
        background: $white-color-ev;
        text-decoration: none;
    }
}

.cta-button {
    border: none;
    background: $accent-color-ev;
    color: $white-color-ev !important;

    &:hover {
        color: $white-color-ev !important;
        background: $dark-accent-color-ev;
        text-decoration: none;
    }
}

/*


.close-button {
    border: none;
    color: $white-color-ev !important;
    padding: 5px 15px;
    border-radius: 5px;
    background: $error-color-ev;


    &:hover {
        border: none;
        color: $white-color-ev !important;
        background: lighten($error-color-ev, 5%);
        text-decoration: none;
    }
}

.add-button {
    border-radius: 7px;
    padding: 6px 15px;
    background-color: darken($base-color-ev, 10%);
    color: $white-color-ev;
    border: none;
}

.primary-button {
    border-radius: 7px;
    padding: 10px 3rem;
    background-color: darken($secondary-color-ev, 10%);
    color: $white-color-ev !important;
    border: none;

    &:hover {
        color: $white-color-ev !important;
        background: $dark-secondary-color-ev;
        text-decoration: none;
    }
}

.secondary-button {
    border-radius: 7px;
    padding: 10px 2rem;
    background-color: darken($base-color-ev, 10%);
    color: $white-color-ev !important;
    border: none;

    &:hover {
        color: $white-color-ev !important;
        background: $base-color-ev;
        text-decoration: none;
    }
}
*/

