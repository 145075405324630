.wrapper {
    width: 100%;
    max-width: 100%;
}

.content-wrapper {
    width: 90%;
    max-width: 1170px;
    margin: 0 auto;
}

.row {
    margin: 0;
    padding: 0;
}
