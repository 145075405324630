* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

/* Display/Heading Fonts */
@font-face {
  font-family: "alegreyasans-light";
  src: url("../fonts/alegreyasans-light.woff2") format("woff2"), url("../fonts/alegreyasans-light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "alegreyasans-regular";
  src: url("../fonts/alegreyasans-regular.woff2") format("woff2"), url("../fonts/alegreyasans-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "alegreyasans-medium";
  src: url("../fonts/alegreyasans-medium.woff2") format("woff2"), url("../fonts/alegreyasans-medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/*@font-face {
    font-family: 'alegreyasans-semibold';
    src: url('../fonts/alegreyasans-semibold.woff2') format('woff2'), url('../fonts/alegreyasans-semibold.woff') format('woff');
    font-weight: normal; // Font-weight = 600
    font-style: normal;
}*/
@font-face {
  font-family: "alegreyasans-bold";
  src: url("../fonts/alegreyasans-bold.woff2") format("woff2"), url("../fonts/alegreyasans-bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "alegreyasans-extrabold";
  src: url("../fonts/alegreyasans-extrabold.woff2") format("woff2"), url("../fonts/alegreyasans-extrabold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "alegreyasans-black";
  src: url("../fonts/alegreyasans-black.woff2") format("woff2"), url("../fonts/alegreyasans-black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
/* Body Fonts */
/*
@font-face {
    font-family: 'alegreyasans-light';
    src: url('../fonts/alegreyasans-light.woff2') format('woff2'), url('../fonts/alegreyasans-light.woff') format('woff');
    font-weight: normal; // Font-weight = 300
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-regular';
    src: url('../fonts/alegreyasans-regular.woff2') format('woff2'), url('../fonts/alegreyasans-regular.woff') format('woff');
    font-weight: normal; // Font-weight = 400
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-medium';
    src: url('../fonts/alegreyasans-medium.woff2') format('woff2'), url('../fonts/alegreyasans-medium.woff') format('woff');
    font-weight: normal; // Font-weight = 500
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-semibold';
    src: url('../fonts/alegreyasans-semibold.woff2') format('woff2'), url('../fonts/alegreyasans-semibold.woff') format('woff');
    font-weight: normal; // Font-weight = 600
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-bold';
    src: url('../fonts/alegreyasans-bold.woff2') format('woff2'), url('../fonts/alegreyasans-bold.woff') format('woff');
    font-weight: normal; // Font-weight = 700
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-extrabold';
    src: url('../fonts/alegreyasans-extrabold.woff2') format('woff2'), url('../fonts/alegreyasans-extrabold.woff') format('woff');
    font-weight: normal; // Font-weight = 800
    font-style: normal;
}

@font-face {
    font-family: 'alegreyasans-black';
    src: url('../fonts/alegreyasans-black.woff2') format('woff2'), url('../fonts/alegreyasans-black.woff') format('woff');
    font-weight: normal; // Font-weight = 900
    font-style: normal;
}
*/
/* ------------ Colors Variables -------------*/
/* ------------ Space Variables -------------*/
/* ------------ Fonts Variables -------------*/
/**
 * Basic typography style for copy text
 */
body {
  font-family: "alegreyasans-regular", Helvetica, Arial, sans-serif !important;
  font-size: 16px;
  color: #262e3d;
  line-height: 1.6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "alegreyasans-medium", Helvetica, Arial, sans-serif !important;
}

p {
  padding: 0.5rem 0;
}

@media screen and (min-width: 1441px) {
  h1 {
    font-size: 3.2rem;
    line-height: 1.3;
  }
  h2 {
    font-size: 2.1rem;
    line-height: 1.3;
  }
  h3 {
    font-size: 1.8rem;
    line-height: 1.3;
  }
  h4 {
    font-size: 1.6rem;
    line-height: 1.4;
  }
  h5 {
    font-size: 1.4rem;
    line-height: 1.4;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.5;
  }
  small {
    font-size: 0.95rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  h1 {
    font-size: 2.9rem;
    line-height: 1.3;
  }
  h2 {
    font-size: 2rem;
    line-height: 1.3;
  }
  h3 {
    font-size: 1.7rem;
    line-height: 1.3;
  }
  h4 {
    font-size: 1.5rem;
    line-height: 1.4;
  }
  h5 {
    font-size: 1.3rem;
    line-height: 1.5;
  }
  p {
    font-size: 1rem;
    line-height: 1.6;
  }
  small {
    font-size: 0.9rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  h1 {
    font-size: 2.6rem;
    line-height: 1.3;
  }
  h2 {
    font-size: 1.9rem;
    line-height: 1.3;
  }
  h3 {
    font-size: 1.6rem;
    line-height: 1.4;
  }
  h4 {
    font-size: 1.4rem;
    line-height: 1.4;
  }
  h5 {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  p {
    font-size: 1rem;
    line-height: 1.5;
  }
  small {
    font-size: 0.9rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 421px) and (max-width: 768px) {
  h1 {
    font-size: 2.4rem;
    line-height: 1.3;
  }
  h2 {
    font-size: 1.8rem;
    line-height: 1.4;
  }
  h3 {
    font-size: 1.5rem;
    line-height: 1.4;
  }
  h4 {
    font-size: 1.3rem;
    line-height: 1.5;
  }
  h5 {
    font-size: 1.15rem;
    line-height: 1.5;
  }
  p {
    font-size: 1rem;
    line-height: 1.5;
  }
  .small-text {
    font-size: 0.9rem;
    line-height: 1.5;
  }
}
@media screen and (max-width: 420px) {
  h1 {
    font-size: 2.2rem;
    line-height: 1.4;
  }
  h2 {
    font-size: 1.7rem;
    line-height: 1.4;
  }
  h3 {
    font-size: 1.4rem;
    line-height: 1.4;
  }
  h4 {
    font-size: 1.2rem;
    line-height: 1.4;
  }
  h5 {
    font-size: 1rem;
    line-height: 1.4;
  }
  p {
    font-size: 0.95rem;
    line-height: 1.5;
  }
  small {
    font-size: 0.85rem;
    line-height: 1.5;
  }
}
html {
  position: relative;
}

body {
  background-color: #ffffff;
  min-width: 300px !important;
  position: relative;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

hr {
  margin: 0.1rem 0;
}

a {
  text-decoration: none;
  color: #610c04;
}
a:hover {
  color: #300602;
}

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

section.owl_carousel {
  width: 100%;
  height: auto;
  overflow: hidden;
}
section.owl_carousel .item {
  background-color: #f68019;
  height: auto;
  color: #f3f3f3;
}

section.parallax .parallax-bg {
  background: transparent;
}
section.parallax .parallax-bg .parallax-content {
  color: #f3f3f3;
  z-index: 2;
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 150px 50px;
}
section.parallax .parallax-bg .parallax-content h1,
section.parallax .parallax-bg .parallax-content p {
  margin: 20px auto;
  text-align: center;
}

section.slick {
  width: 100%;
  background-color: #1a202a;
  color: #f3f3f3;
}
section.slick .client {
  width: 30%;
  overflow: hidden;
  padding: 20px 5px;
}
section.slick .client img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

div.pagination-nav .pager {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  margin: 0;
}
div.pagination-nav .pager li span, div.pagination-nav .pager li a {
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  border: 1px solid #f3f3f3;
}
div.pagination-nav .pager li a:hover {
  background-color: #f3f3f3;
  border: 1px solid #8991a0;
}

.active-paginate-link span {
  background-color: #5cb85c !important;
  color: white !important;
  border-color: #5cb85c !important;
}

table#datatable {
  background-color: #ffffff !important;
}
table#datatable th {
  background-color: #1a202a;
  color: #f3f3f3;
}
table#datatable .idCol {
  width: 50px !important;
}
table#datatable .actionsCol {
  width: 100px !important;
}

#datatable_paginate .page-item .page-link {
  color: #610c04;
}
#datatable_paginate .page-item .page-link:focus {
  outline: none;
  border-color: #c31808;
  box-shadow: 0 0 2px #610c04;
}
#datatable_paginate .page-item.active .page-link {
  color: #ffffff;
  background-color: #610c04;
  border-color: #610c04;
}
#datatable_paginate .page-item.disabled .page-link {
  color: #8991a0;
}

div.dataTables_length label select:focus {
  outline: none;
  border-color: #c31808;
  box-shadow: 0 0 2px #610c04;
}
div.dataTables_length label select option {
  background-color: #610c04 !important;
  color: #ffffff;
}
div.dataTables_length label select option:hover {
  background-color: #610c04 !important;
}
div.dataTables_length label select option:focus {
  background-color: #610c04 !important;
}

div.dataTables_filter input[type=search]:focus {
  outline: none;
  border-color: #c31808;
  box-shadow: 0 0 2px #610c04;
}

.dataTables_wrapper div.dataTables_wrapper div.dataTables_filter select {
  color: #ffffff;
  background-color: #1a202a;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.alert {
  margin: 1rem auto 0;
  width: 90%;
  max-width: 1170px;
  padding: 0.5rem 1rem;
}

.breadcrumb-item {
  font-size: 0.8em;
}

.bg-white, .btn-white {
  background-color: #ffffff;
  color: #262e3d;
}

.bg-light-gray, .btn-light-gray {
  background-color: #f3f3f3;
  color: #262e3d;
}

.bg-dark-gray, .btn-dark-gray {
  background-color: #1a202a;
  color: #ffffff;
}

.bg-base, .btn-base {
  background-color: #610c04;
  color: #ffffff !important;
}

.bg-dark-base, .btn-dark-base {
  background-color: #260101;
  color: #ffffff;
}

.bg-secondary, .btn-secondary {
  background-color: #260101 !important;
  color: #ffffff !important;
}

.bg-dark-secondary, .btn-dark-secondary {
  background-color: #260101;
  color: #ffffff;
}

.bg-accent, .btn-accent {
  background-color: #f68019;
  color: #ffffff;
}

.bg-dark-accent, .btn-dark-accent {
  background-color: #ff671f;
  color: #ffffff;
}

/** Text Position */
.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

/** Text Color */
.txt-white {
  color: #ffffff;
}

.txt-disabled {
  color: #8991a0;
}

.txt-base {
  color: #610c04;
}

.txt-base-darken {
  color: #260101;
}

.txt-secondary {
  color: #260101;
}

.txt-secondary-darken {
  color: #110000;
}

.txt-accent {
  color: #f68019;
}

.txt-accent-darken {
  color: #ff671f;
}

.dark-thick-heading {
  margin-right: 0.5rem;
  color: #1a202a;
}

.white-thick-heading {
  margin-right: 0.5rem;
  color: #ffffff;
}

.base-thin-heading {
  color: #610c04;
  font-family: "alegreyasans-regular", Helvetica, Arial, sans-serif !important;
}

/* Margin All */
.mg-0 {
  margin: 0;
}

.mg-0_25 {
  margin: 0.25rem;
}

.mg-0_5 {
  margin: 0.5rem;
}

.mg-0_75 {
  margin: 0.75rem;
}

.mg-1 {
  margin: 1rem;
}

.mg-1_25 {
  margin: 1.25rem;
}

.mg-1_5 {
  margin: 1.5rem;
}

.mg-1_75 {
  margin: 1.75rem;
}

.mg-2 {
  margin: 2rem;
}

.mg-2_25 {
  margin: 2.25rem;
}

.mg-2_5 {
  margin: 2.5rem;
}

.mg-2_75 {
  margin: 2.75rem;
}

.mg-3 {
  margin: 3rem;
}

.mg-3_25 {
  margin: 3.25rem;
}

.mg-3_5 {
  margin: 3.5rem;
}

.mg-3_75 {
  margin: 3.75rem;
}

.mg-4 {
  margin: 4rem;
}

.mg-4_25 {
  margin: 4.25rem;
}

.mg-4_5 {
  margin: 4.5rem;
}

.mg-4_75 {
  margin: 4.75rem;
}

.mg-5 {
  margin: 5rem;
}

.mg-5_25 {
  margin: 5.25rem;
}

.mg-5_5 {
  margin: 5.5rem;
}

.mg-5_75 {
  margin: 5.75rem;
}

.mg-6 {
  margin: 6rem;
}

.mg-6_25 {
  margin: 6.25rem;
}

.mg-6_5 {
  margin: 6.5rem;
}

.mg-6_75 {
  margin: 6.75rem;
}

.mg-7 {
  margin: 7rem;
}

.mg-7_25 {
  margin: 7.25rem;
}

.mg-7_5 {
  margin: 7.5rem;
}

.mg-7_75 {
  margin: 7.75rem;
}

.mg-8 {
  margin: 8rem;
}

.mg-8_25 {
  margin: 8.25rem;
}

.mg-8_5 {
  margin: 8.5rem;
}

.mg-8_75 {
  margin: 8.75rem;
}

.mg-9 {
  margin: 9rem;
}

.mg-9_25 {
  margin: 9.25rem;
}

.mg-9_5 {
  margin: 9.5rem;
}

.mg-9_75 {
  margin: 9.75rem;
}

.mg-10 {
  margin: 10rem;
}

/* Margin Vertical */
.mg-y-0_25 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.mg-y-0_5 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mg-y-0_75 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.mg-y-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mg-y-1_25 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mg-y-1_5 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mg-y-1_75 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.mg-y-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mg-y-2_25 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.mg-y-2_5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mg-y-2_75 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.mg-y-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mg-y-3_25 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem;
}

.mg-y-3_5 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.mg-y-3_75 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.mg-y-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mg-y-4_25 {
  margin-top: 4.25rem;
  margin-bottom: 4.25rem;
}

.mg-y-4_5 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.mg-y-4_75 {
  margin-top: 4.75rem;
  margin-bottom: 4.75rem;
}

.mg-y-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mg-y-5_25 {
  margin-top: 5.25rem;
  margin-bottom: 5.25rem;
}

.mg-y-5_5 {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}

.mg-y-5_75 {
  margin-top: 5.75rem;
  margin-bottom: 5.75rem;
}

.mg-y-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.mg-y-6_25 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}

.mg-y-6_5 {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}

.mg-y-6_75 {
  margin-top: 6.75rem;
  margin-bottom: 6.75rem;
}

.mg-y-7 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.mg-y-7_25 {
  margin-top: 7.25rem;
  margin-bottom: 7.25rem;
}

.mg-y-7_5 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.mg-y-7_75 {
  margin-top: 7.75rem;
  margin-bottom: 7.75rem;
}

.mg-y-8 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mg-y-8_25 {
  margin-top: 8.25rem;
  margin-bottom: 8.25rem;
}

.mg-y-8_5 {
  margin-top: 8.5rem;
  margin-bottom: 8.5rem;
}

.mg-y-8_75 {
  margin-top: 8.75rem;
  margin-bottom: 8.75rem;
}

.mg-y-9 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.mg-y-9_25 {
  margin-top: 9.25rem;
  margin-bottom: 9.25rem;
}

.mg-y-9_5 {
  margin-top: 9.5rem;
  margin-bottom: 9.5rem;
}

.mg-y-9_75 {
  margin-top: 9.75rem;
  margin-bottom: 9.75rem;
}

.mg-y-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

/* Margin Horizontal */
.mg-x-0_25 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mg-x-0_5 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mg-x-0_75 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mg-x-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mg-x-1_25 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mg-x-1_5 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mg-x-1_75 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.mg-x-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mg-x-2_25 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.mg-x-2_5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mg-x-2_75 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.mg-x-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mg-x-3_25 {
  margin-left: 3.25rem;
  margin-right: 3.25rem;
}

.mg-x-3_5 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.mg-x-3_75 {
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}

.mg-x-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mg-x-4_25 {
  margin-left: 4.25rem;
  margin-right: 4.25rem;
}

.mg-x-4_5 {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}

.mg-x-4_75 {
  margin-left: 4.75rem;
  margin-right: 4.75rem;
}

.mg-x-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mg-x-5_25 {
  margin-left: 5.25rem;
  margin-right: 5.25rem;
}

.mg-x-5_5 {
  margin-left: 5.5rem;
  margin-right: 5.5rem;
}

.mg-x-5_75 {
  margin-left: 5.75rem;
  margin-right: 5.75rem;
}

.mg-x-6 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.mg-x-6_25 {
  margin-left: 6.25rem;
  margin-right: 6.25rem;
}

.mg-x-6_5 {
  margin-left: 6.5rem;
  margin-right: 6.5rem;
}

.mg-x-6_75 {
  margin-left: 6.75rem;
  margin-right: 6.75rem;
}

.mg-x-7 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.mg-x-7_25 {
  margin-left: 7.25rem;
  margin-right: 7.25rem;
}

.mg-x-7_5 {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}

.mg-x-7_75 {
  margin-left: 7.75rem;
  margin-right: 7.75rem;
}

.mg-x-8 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.mg-x-8_25 {
  margin-left: 8.25rem;
  margin-right: 8.25rem;
}

.mg-x-8_5 {
  margin-left: 8.5rem;
  margin-right: 8.5rem;
}

.mg-x-8_75 {
  margin-left: 8.75rem;
  margin-right: 8.75rem;
}

.mg-x-9 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.mg-x-9_25 {
  margin-left: 9.25rem;
  margin-right: 9.25rem;
}

.mg-x-9_5 {
  margin-left: 9.5rem;
  margin-right: 9.5rem;
}

.mg-x-9_75 {
  margin-left: 9.75rem;
  margin-right: 9.75rem;
}

.mg-x-10 {
  margin-left: 10rem;
  margin-right: 10rem;
}

/* Margin Top */
.mg-t-0_25 {
  margin-top: 0.25rem;
}

.mg-t-0_5 {
  margin-top: 0.5rem;
}

.mg-t-0_75 {
  margin-top: 0.75rem;
}

.mg-t-1 {
  margin-top: 1rem;
}

.mg-t-1_25 {
  margin-top: 1.25rem;
}

.mg-t-1_5 {
  margin-top: 1.5rem;
}

.mg-t-1_75 {
  margin-top: 1.75rem;
}

.mg-t-2 {
  margin-top: 2rem;
}

.mg-t-2_25 {
  margin-top: 2.25rem;
}

.mg-t-2_5 {
  margin-top: 2.5rem;
}

.mg-t-2_75 {
  margin-top: 2.75rem;
}

.mg-t-3 {
  margin-top: 3rem;
}

.mg-t-3_25 {
  margin-top: 3.25rem;
}

.mg-t-3_5 {
  margin-top: 3.5rem;
}

.mg-t-3_75 {
  margin-top: 3.75rem;
}

.mg-t-4 {
  margin-top: 4rem;
}

.mg-t-4_25 {
  margin-top: 4.25rem;
}

.mg-t-4_5 {
  margin-top: 4.5rem;
}

.mg-t-4_75 {
  margin-top: 4.75rem;
}

.mg-t-5 {
  margin-top: 5rem;
}

.mg-t-5_25 {
  margin-top: 5.25rem;
}

.mg-t-5_5 {
  margin-top: 5.5rem;
}

.mg-t-5_75 {
  margin-top: 5.75rem;
}

.mg-t-6 {
  margin-top: 6rem;
}

.mg-t-6_25 {
  margin-top: 6.25rem;
}

.mg-t-6_5 {
  margin-top: 6.5rem;
}

.mg-t-6_75 {
  margin-top: 6.75rem;
}

.mg-t-7 {
  margin-top: 7rem;
}

.mg-t-7_25 {
  margin-top: 7.25rem;
}

.mg-t-7_5 {
  margin-top: 7.5rem;
}

.mg-t-7_75 {
  margin-top: 7.75rem;
}

.mg-t-8 {
  margin-top: 8rem;
}

.mg-t-8_25 {
  margin-top: 8.25rem;
}

.mg-t-8_5 {
  margin-top: 8.5rem;
}

.mg-t-8_75 {
  margin-top: 8.75rem;
}

.mg-t-9 {
  margin-top: 9rem;
}

.mg-t-9_25 {
  margin-top: 9.25rem;
}

.mg-t-9_5 {
  margin-top: 9.5rem;
}

.mg-t-9_75 {
  margin-top: 9.75rem;
}

.mg-t-10 {
  margin-top: 10rem;
}

/* Margin Right */
.mg-r-0_25 {
  margin-right: 0.25rem;
}

.mg-r-0_5 {
  margin-right: 0.5rem;
}

.mg-r-0_75 {
  margin-right: 0.75rem;
}

.mg-r-1 {
  margin-right: 1rem;
}

.mg-r-1_25 {
  margin-right: 1.25rem;
}

.mg-r-1_5 {
  margin-right: 1.5rem;
}

.mg-r-1_75 {
  margin-right: 1.75rem;
}

.mg-r-2 {
  margin-right: 2rem;
}

.mg-r-2_25 {
  margin-right: 2.25rem;
}

.mg-r-2_5 {
  margin-right: 2.5rem;
}

.mg-r-2_75 {
  margin-right: 2.75rem;
}

.mg-r-3 {
  margin-right: 3rem;
}

.mg-r-3_25 {
  margin-right: 3.25rem;
}

.mg-r-3_5 {
  margin-right: 3.5rem;
}

.mg-r-3_75 {
  margin-right: 3.75rem;
}

.mg-r-4 {
  margin-right: 4rem;
}

.mg-r-4_25 {
  margin-right: 4.25rem;
}

.mg-r-4_5 {
  margin-right: 4.5rem;
}

.mg-r-4_75 {
  margin-right: 4.75rem;
}

.mg-r-5 {
  margin-right: 5rem;
}

.mg-r-5_25 {
  margin-right: 5.25rem;
}

.mg-r-5_5 {
  margin-right: 5.5rem;
}

.mg-r-5_75 {
  margin-right: 5.75rem;
}

.mg-r-6 {
  margin-right: 6rem;
}

.mg-r-6_25 {
  margin-right: 6.25rem;
}

.mg-r-6_5 {
  margin-right: 6.5rem;
}

.mg-r-6_75 {
  margin-right: 6.75rem;
}

.mg-r-7 {
  margin-right: 7rem;
}

.mg-r-7_25 {
  margin-right: 7.25rem;
}

.mg-r-7_5 {
  margin-right: 7.5rem;
}

.mg-r-7_75 {
  margin-right: 7.75rem;
}

.mg-r-8 {
  margin-right: 8rem;
}

.mg-r-8_25 {
  margin-right: 8.25rem;
}

.mg-r-8_5 {
  margin-right: 8.5rem;
}

.mg-r-8_75 {
  margin-right: 8.75rem;
}

.mg-r-9 {
  margin-right: 9rem;
}

.mg-r-9_25 {
  margin-right: 9.25rem;
}

.mg-r-9_5 {
  margin-right: 9.5rem;
}

.mg-r-9_75 {
  margin-right: 9.75rem;
}

.mg-r-10 {
  margin-right: 10rem;
}

/* Margin Bottom */
.mg-b-0_25 {
  margin-bottom: 0.25rem;
}

.mg-b-0_5 {
  margin-bottom: 0.5rem;
}

.mg-b-0_75 {
  margin-bottom: 0.75rem;
}

.mg-b-1 {
  margin-bottom: 1rem;
}

.mg-b-1_25 {
  margin-bottom: 1.25rem;
}

.mg-b-1_5 {
  margin-bottom: 1.5rem;
}

.mg-b-1_75 {
  margin-bottom: 1.75rem;
}

.mg-b-2 {
  margin-bottom: 2rem;
}

.mg-b-2_25 {
  margin-bottom: 2.25rem;
}

.mg-b-2_5 {
  margin-bottom: 2.5rem;
}

.mg-b-2_75 {
  margin-bottom: 2.75rem;
}

.mg-b-3 {
  margin-bottom: 3rem;
}

.mg-b-3_25 {
  margin-bottom: 3.25rem;
}

.mg-b-3_5 {
  margin-bottom: 3.5rem;
}

.mg-b-3_75 {
  margin-bottom: 3.75rem;
}

.mg-b-4 {
  margin-bottom: 4rem;
}

.mg-b-4_25 {
  margin-bottom: 4.25rem;
}

.mg-b-4_5 {
  margin-bottom: 4.5rem;
}

.mg-b-4_75 {
  margin-bottom: 4.75rem;
}

.mg-b-5 {
  margin-bottom: 5rem;
}

.mg-b-5_25 {
  margin-bottom: 5.25rem;
}

.mg-b-5_5 {
  margin-bottom: 5.5rem;
}

.mg-b-5_75 {
  margin-bottom: 5.75rem;
}

.mg-b-6 {
  margin-bottom: 6rem;
}

.mg-b-6_25 {
  margin-bottom: 6.25rem;
}

.mg-b-6_5 {
  margin-bottom: 6.5rem;
}

.mg-b-6_75 {
  margin-bottom: 6.75rem;
}

.mg-b-7 {
  margin-bottom: 7rem;
}

.mg-b-7_25 {
  margin-bottom: 7.25rem;
}

.mg-b-7_5 {
  margin-bottom: 7.5rem;
}

.mg-b-7_75 {
  margin-bottom: 7.75rem;
}

.mg-b-8 {
  margin-bottom: 8rem;
}

.mg-b-8_25 {
  margin-bottom: 8.25rem;
}

.mg-b-8_5 {
  margin-bottom: 8.5rem;
}

.mg-b-8_75 {
  margin-bottom: 8.75rem;
}

.mg-b-9 {
  margin-bottom: 9rem;
}

.mg-b-9_25 {
  margin-bottom: 9.25rem;
}

.mg-b-9_5 {
  margin-bottom: 9.5rem;
}

.mg-b-9_75 {
  margin-bottom: 9.75rem;
}

.mg-b-10 {
  margin-bottom: 10rem;
}

/* Margin Left */
.mg-l-0_25 {
  margin-left: 0.25rem;
}

.mg-l-0_5 {
  margin-left: 0.5rem;
}

.mg-l-0_75 {
  margin-left: 0.75rem;
}

.mg-l-1 {
  margin-left: 1rem;
}

.mg-l-1_25 {
  margin-left: 1.25rem;
}

.mg-l-1_5 {
  margin-left: 1.5rem;
}

.mg-l-1_75 {
  margin-left: 1.75rem;
}

.mg-l-2 {
  margin-left: 2rem;
}

.mg-l-2_25 {
  margin-left: 2.25rem;
}

.mg-l-2_5 {
  margin-left: 2.5rem;
}

.mg-l-2_75 {
  margin-left: 2.75rem;
}

.mg-l-3 {
  margin-left: 3rem;
}

.mg-l-3_25 {
  margin-left: 3.25rem;
}

.mg-l-3_5 {
  margin-left: 3.5rem;
}

.mg-l-3_75 {
  margin-left: 3.75rem;
}

.mg-l-4 {
  margin-left: 4rem;
}

.mg-l-4_25 {
  margin-left: 4.25rem;
}

.mg-l-4_5 {
  margin-left: 4.5rem;
}

.mg-l-4_75 {
  margin-left: 4.75rem;
}

.mg-l-5 {
  margin-left: 5rem;
}

.mg-l-5_25 {
  margin-left: 5.25rem;
}

.mg-l-5_5 {
  margin-left: 5.5rem;
}

.mg-l-5_75 {
  margin-left: 5.75rem;
}

.mg-l-6 {
  margin-left: 6rem;
}

.mg-l-6_25 {
  margin-left: 6.25rem;
}

.mg-l-6_5 {
  margin-left: 6.5rem;
}

.mg-l-6_75 {
  margin-left: 6.75rem;
}

.mg-l-7 {
  margin-left: 7rem;
}

.mg-l-7_25 {
  margin-left: 7.25rem;
}

.mg-l-7_5 {
  margin-left: 7.5rem;
}

.mg-l-7_75 {
  margin-left: 7.75rem;
}

.mg-l-8 {
  margin-left: 8rem;
}

.mg-l-8_25 {
  margin-left: 8.25rem;
}

.mg-l-8_5 {
  margin-left: 8.5rem;
}

.mg-l-8_75 {
  margin-left: 8.75rem;
}

.mg-l-9 {
  margin-left: 9rem;
}

.mg-l-9_25 {
  margin-left: 9.25rem;
}

.mg-l-9_5 {
  margin-left: 9.5rem;
}

.mg-l-9_75 {
  margin-left: 9.75rem;
}

.mg-l-10 {
  margin-left: 10rem;
}

/* Padding All */
.pd-0 {
  padding: 0;
}

.pd-0_25 {
  padding: 0.25rem;
}

.pd-0_5 {
  padding: 0.5rem;
}

.pd-0_75 {
  padding: 0.75rem;
}

.pd-1 {
  padding: 1rem;
}

.pd-1_25 {
  padding: 1.25rem;
}

.pd-1_5 {
  padding: 1.5rem;
}

.pd-1_75 {
  padding: 1.75rem;
}

.pd-2 {
  padding: 2rem;
}

.pd-2_25 {
  padding: 2.25rem;
}

.pd-2_5 {
  padding: 2.5rem;
}

.pd-2_75 {
  padding: 2.75rem;
}

.pd-3 {
  padding: 3rem;
}

.pd-3_25 {
  padding: 3.25rem;
}

.pd-3_5 {
  padding: 3.5rem;
}

.pd-3_75 {
  padding: 3.75rem;
}

.pd-4 {
  padding: 4rem;
}

.pd-4_25 {
  padding: 4.25rem;
}

.pd-4_5 {
  padding: 4.5rem;
}

.pd-4_75 {
  padding: 4.75rem;
}

.pd-5 {
  padding: 5rem;
}

.pd-5_25 {
  padding: 5.25rem;
}

.pd-5_5 {
  padding: 5.5rem;
}

.pd-5_75 {
  padding: 5.75rem;
}

.pd-6 {
  padding: 6rem;
}

.pd-6_25 {
  padding: 6.25rem;
}

.pd-6_5 {
  padding: 6.5rem;
}

.pd-6_75 {
  padding: 6.75rem;
}

.pd-7 {
  padding: 7rem;
}

.pd-7_25 {
  padding: 7.25rem;
}

.pd-7_5 {
  padding: 7.5rem;
}

.pd-7_75 {
  padding: 7.75rem;
}

.pd-8 {
  padding: 8rem;
}

.pd-8_25 {
  padding: 8.25rem;
}

.pd-8_5 {
  padding: 8.5rem;
}

.pd-8_75 {
  padding: 8.75rem;
}

.pd-9 {
  padding: 9rem;
}

.pd-9_25 {
  padding: 9.25rem;
}

.pd-9_5 {
  padding: 9.5rem;
}

.pd-9_75 {
  padding: 9.75rem;
}

.pd-10 {
  padding: 10rem;
}

/* Padding Vertical */
.pd-y-0_25 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.pd-y-0_5 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pd-y-0_75 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.pd-y-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pd-y-1_25 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pd-y-1_5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pd-y-1_75 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.pd-y-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pd-y-2_25 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.pd-y-2_5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pd-y-2_75 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.pd-y-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pd-y-3_25 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem;
}

.pd-y-3_5 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.pd-y-3_75 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.pd-y-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pd-y-4_25 {
  padding-top: 4.25rem;
  padding-bottom: 4.25rem;
}

.pd-y-4_5 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.pd-y-4_75 {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
}

.pd-y-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pd-y-5_25 {
  padding-top: 5.25rem;
  padding-bottom: 5.25rem;
}

.pd-y-5_5 {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
}

.pd-y-5_75 {
  padding-top: 5.75rem;
  padding-bottom: 5.75rem;
}

.pd-y-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.pd-y-6_25 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

.pd-y-6_5 {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem;
}

.pd-y-6_75 {
  padding-top: 6.75rem;
  padding-bottom: 6.75rem;
}

.pd-y-7 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.pd-y-7_25 {
  padding-top: 7.25rem;
  padding-bottom: 7.25rem;
}

.pd-y-7_5 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.pd-y-7_75 {
  padding-top: 7.75rem;
  padding-bottom: 7.75rem;
}

.pd-y-8 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.pd-y-8_25 {
  padding-top: 8.25rem;
  padding-bottom: 8.25rem;
}

.pd-y-8_5 {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
}

.pd-y-8_75 {
  padding-top: 8.75rem;
  padding-bottom: 8.75rem;
}

.pd-y-9 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.pd-y-9_25 {
  padding-top: 9.25rem;
  padding-bottom: 9.25rem;
}

.pd-y-9_5 {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
}

.pd-y-9_75 {
  padding-top: 9.75rem;
  padding-bottom: 9.75rem;
}

.pd-y-10 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

/* Padding Horizontal */
.pd-x-0_25 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.pd-x-0_5 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.pd-x-0_75 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.pd-x-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pd-x-1_25 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.pd-x-1_5 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pd-x-1_75 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.pd-x-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pd-x-2_25 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.pd-x-2_5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pd-x-2_75 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.pd-x-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pd-x-3_25 {
  padding-left: 3.25rem;
  padding-right: 3.25rem;
}

.pd-x-3_5 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.pd-x-3_75 {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.pd-x-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.pd-x-4_25 {
  padding-left: 4.25rem;
  padding-right: 4.25rem;
}

.pd-x-4_5 {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.pd-x-4_75 {
  padding-left: 4.75rem;
  padding-right: 4.75rem;
}

.pd-x-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.pd-x-5_25 {
  padding-left: 5.25rem;
  padding-right: 5.25rem;
}

.pd-x-5_5 {
  padding-left: 5.5rem;
  padding-right: 5.5rem;
}

.pd-x-5_75 {
  padding-left: 5.75rem;
  padding-right: 5.75rem;
}

.pd-x-6 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.pd-x-6_25 {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
}

.pd-x-6_5 {
  padding-left: 6.5rem;
  padding-right: 6.5rem;
}

.pd-x-6_75 {
  padding-left: 6.75rem;
  padding-right: 6.75rem;
}

.pd-x-7 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.pd-x-7_25 {
  padding-left: 7.25rem;
  padding-right: 7.25rem;
}

.pd-x-7_5 {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
}

.pd-x-7_75 {
  padding-left: 7.75rem;
  padding-right: 7.75rem;
}

.pd-x-8 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.pd-x-8_25 {
  padding-left: 8.25rem;
  padding-right: 8.25rem;
}

.pd-x-8_5 {
  padding-left: 8.5rem;
  padding-right: 8.5rem;
}

.pd-x-8_75 {
  padding-left: 8.75rem;
  padding-right: 8.75rem;
}

.pd-x-9 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.pd-x-9_25 {
  padding-left: 9.25rem;
  padding-right: 9.25rem;
}

.pd-x-9_5 {
  padding-left: 9.5rem;
  padding-right: 9.5rem;
}

.pd-x-9_75 {
  padding-left: 9.75rem;
  padding-right: 9.75rem;
}

.pd-x-10 {
  padding-left: 10rem;
  padding-right: 10rem;
}

/* Padding Top */
.pd-t-0_25 {
  padding-top: 0.25rem;
}

.pd-t-0_5 {
  padding-top: 0.5rem;
}

.pd-t-0_75 {
  padding-top: 0.75rem;
}

.pd-t-1 {
  padding-top: 1rem;
}

.pd-t-1_25 {
  padding-top: 1.25rem;
}

.pd-t-1_5 {
  padding-top: 1.5rem;
}

.pd-t-1_75 {
  padding-top: 1.75rem;
}

.pd-t-2 {
  padding-top: 2rem;
}

.pd-t-2_25 {
  padding-top: 2.25rem;
}

.pd-t-2_5 {
  padding-top: 2.5rem;
}

.pd-t-2_75 {
  padding-top: 2.75rem;
}

.pd-t-3 {
  padding-top: 3rem;
}

.pd-t-3_25 {
  padding-top: 3.25rem;
}

.pd-t-3_5 {
  padding-top: 3.5rem;
}

.pd-t-3_75 {
  padding-top: 3.75rem;
}

.pd-t-4 {
  padding-top: 4rem;
}

.pd-t-4_25 {
  padding-top: 4.25rem;
}

.pd-t-4_5 {
  padding-top: 4.5rem;
}

.pd-t-4_75 {
  padding-top: 4.75rem;
}

.pd-t-5 {
  padding-top: 5rem;
}

.pd-t-5_25 {
  padding-top: 5.25rem;
}

.pd-t-5_5 {
  padding-top: 5.5rem;
}

.pd-t-5_75 {
  padding-top: 5.75rem;
}

.pd-t-6 {
  padding-top: 6rem;
}

.pd-t-6_25 {
  padding-top: 6.25rem;
}

.pd-t-6_5 {
  padding-top: 6.5rem;
}

.pd-t-6_75 {
  padding-top: 6.75rem;
}

.pd-t-7 {
  padding-top: 7rem;
}

.pd-t-7_25 {
  padding-top: 7.25rem;
}

.pd-t-7_5 {
  padding-top: 7.5rem;
}

.pd-t-7_75 {
  padding-top: 7.75rem;
}

.pd-t-8 {
  padding-top: 8rem;
}

.pd-t-8_25 {
  padding-top: 8.25rem;
}

.pd-t-8_5 {
  padding-top: 8.5rem;
}

.pd-t-8_75 {
  padding-top: 8.75rem;
}

.pd-t-9 {
  padding-top: 9rem;
}

.pd-t-9_25 {
  padding-top: 9.25rem;
}

.pd-t-9_5 {
  padding-top: 9.5rem;
}

.pd-t-9_75 {
  padding-top: 9.75rem;
}

.pd-t-10 {
  padding-top: 10rem;
}

/* Padding Right */
.pd-r-0_25 {
  padding-right: 0.25rem;
}

.pd-r-0_5 {
  padding-right: 0.5rem;
}

.pd-r-0_75 {
  padding-right: 0.75rem;
}

.pd-r-1 {
  padding-right: 1rem;
}

.pd-r-1_25 {
  padding-right: 1.25rem;
}

.pd-r-1_5 {
  padding-right: 1.5rem;
}

.pd-r-1_75 {
  padding-right: 1.75rem;
}

.pd-r-2 {
  padding-right: 2rem;
}

.pd-r-2_25 {
  padding-right: 2.25rem;
}

.pd-r-2_5 {
  padding-right: 2.5rem;
}

.pd-r-2_75 {
  padding-right: 2.75rem;
}

.pd-r-3 {
  padding-right: 3rem;
}

.pd-r-3_25 {
  padding-right: 3.25rem;
}

.pd-r-3_5 {
  padding-right: 3.5rem;
}

.pd-r-3_75 {
  padding-right: 3.75rem;
}

.pd-r-4 {
  padding-right: 4rem;
}

.pd-r-4_25 {
  padding-right: 4.25rem;
}

.pd-r-4_5 {
  padding-right: 4.5rem;
}

.pd-r-4_75 {
  padding-right: 4.75rem;
}

.pd-r-5 {
  padding-right: 5rem;
}

.pd-r-5_25 {
  padding-right: 5.25rem;
}

.pd-r-5_5 {
  padding-right: 5.5rem;
}

.pd-r-5_75 {
  padding-right: 5.75rem;
}

.pd-r-6 {
  padding-right: 6rem;
}

.pd-r-6_25 {
  padding-right: 6.25rem;
}

.pd-r-6_5 {
  padding-right: 6.5rem;
}

.pd-r-6_75 {
  padding-right: 6.75rem;
}

.pd-r-7 {
  padding-right: 7rem;
}

.pd-r-7_25 {
  padding-right: 7.25rem;
}

.pd-r-7_5 {
  padding-right: 7.5rem;
}

.pd-r-7_75 {
  padding-right: 7.75rem;
}

.pd-r-8 {
  padding-right: 8rem;
}

.pd-r-8_25 {
  padding-right: 8.25rem;
}

.pd-r-8_5 {
  padding-right: 8.5rem;
}

.pd-r-8_75 {
  padding-right: 8.75rem;
}

.pd-r-9 {
  padding-right: 9rem;
}

.pd-r-9_25 {
  padding-right: 9.25rem;
}

.pd-r-9_5 {
  padding-right: 9.5rem;
}

.pd-r-9_75 {
  padding-right: 9.75rem;
}

.pd-r-10 {
  padding-right: 10rem;
}

/* Padding Bottom */
.pd-b-0_25 {
  padding-bottom: 0.25rem;
}

.pd-b-0_5 {
  padding-bottom: 0.5rem;
}

.pd-b-0_75 {
  padding-bottom: 0.75rem;
}

.pd-b-1 {
  padding-bottom: 1rem;
}

.pd-b-1_25 {
  padding-bottom: 1.25rem;
}

.pd-b-1_5 {
  padding-bottom: 1.5rem;
}

.pd-b-1_75 {
  padding-bottom: 1.75rem;
}

.pd-b-2 {
  padding-bottom: 2rem;
}

.pd-b-2_25 {
  padding-bottom: 2.25rem;
}

.pd-b-2_5 {
  padding-bottom: 2.5rem;
}

.pd-b-2_75 {
  padding-bottom: 2.75rem;
}

.pd-b-3 {
  padding-bottom: 3rem;
}

.pd-b-3_25 {
  padding-bottom: 3.25rem;
}

.pd-b-3_5 {
  padding-bottom: 3.5rem;
}

.pd-b-3_75 {
  padding-bottom: 3.75rem;
}

.pd-b-4 {
  padding-bottom: 4rem;
}

.pd-b-4_25 {
  padding-bottom: 4.25rem;
}

.pd-b-4_5 {
  padding-bottom: 4.5rem;
}

.pd-b-4_75 {
  padding-bottom: 4.75rem;
}

.pd-b-5 {
  padding-bottom: 5rem;
}

.pd-b-5_25 {
  padding-bottom: 5.25rem;
}

.pd-b-5_5 {
  padding-bottom: 5.5rem;
}

.pd-b-5_75 {
  padding-bottom: 5.75rem;
}

.pd-b-6 {
  padding-bottom: 6rem;
}

.pd-b-6_25 {
  padding-bottom: 6.25rem;
}

.pd-b-6_5 {
  padding-bottom: 6.5rem;
}

.pd-b-6_75 {
  padding-bottom: 6.75rem;
}

.pd-b-7 {
  padding-bottom: 7rem;
}

.pd-b-7_25 {
  padding-bottom: 7.25rem;
}

.pd-b-7_5 {
  padding-bottom: 7.5rem;
}

.pd-b-7_75 {
  padding-bottom: 7.75rem;
}

.pd-b-8 {
  padding-bottom: 8rem;
}

.pd-b-8_25 {
  padding-bottom: 8.25rem;
}

.pd-b-8_5 {
  padding-bottom: 8.5rem;
}

.pd-b-8_75 {
  padding-bottom: 8.75rem;
}

.pd-b-9 {
  padding-bottom: 9rem;
}

.pd-b-9_25 {
  padding-bottom: 9.25rem;
}

.pd-b-9_5 {
  padding-bottom: 9.5rem;
}

.pd-b-9_75 {
  padding-bottom: 9.75rem;
}

.pd-b-10 {
  padding-bottom: 10rem;
}

/* Padding Left */
.pd-l-0_25 {
  padding-left: 0.25rem;
}

.pd-l-0_5 {
  padding-left: 0.5rem;
}

.pd-l-0_75 {
  padding-left: 0.75rem;
}

.pd-l-1 {
  padding-left: 1rem;
}

.pd-l-1_25 {
  padding-left: 1.25rem;
}

.pd-l-1_5 {
  padding-left: 1.5rem;
}

.pd-l-1_75 {
  padding-left: 1.75rem;
}

.pd-l-2 {
  padding-left: 2rem;
}

.pd-l-2_25 {
  padding-left: 2.25rem;
}

.pd-l-2_5 {
  padding-left: 2.5rem;
}

.pd-l-2_75 {
  padding-left: 2.75rem;
}

.pd-l-3 {
  padding-left: 3rem;
}

.pd-l-3_25 {
  padding-left: 3.25rem;
}

.pd-l-3_5 {
  padding-left: 3.5rem;
}

.pd-l-3_75 {
  padding-left: 3.75rem;
}

.pd-l-4 {
  padding-left: 4rem;
}

.pd-l-4_25 {
  padding-left: 4.25rem;
}

.pd-l-4_5 {
  padding-left: 4.5rem;
}

.pd-l-4_75 {
  padding-left: 4.75rem;
}

.pd-l-5 {
  padding-left: 5rem;
}

.pd-l-5_25 {
  padding-left: 5.25rem;
}

.pd-l-5_5 {
  padding-left: 5.5rem;
}

.pd-l-5_75 {
  padding-left: 5.75rem;
}

.pd-l-6 {
  padding-left: 6rem;
}

.pd-l-6_25 {
  padding-left: 6.25rem;
}

.pd-l-6_5 {
  padding-left: 6.5rem;
}

.pd-l-6_75 {
  padding-left: 6.75rem;
}

.pd-l-7 {
  padding-left: 7rem;
}

.pd-l-7_25 {
  padding-left: 7.25rem;
}

.pd-l-7_5 {
  padding-left: 7.5rem;
}

.pd-l-7_75 {
  padding-left: 7.75rem;
}

.pd-l-8 {
  padding-left: 8rem;
}

.pd-l-8_25 {
  padding-left: 8.25rem;
}

.pd-l-8_5 {
  padding-left: 8.5rem;
}

.pd-l-8_75 {
  padding-left: 8.75rem;
}

.pd-l-9 {
  padding-left: 9rem;
}

.pd-l-9_25 {
  padding-left: 9.25rem;
}

.pd-l-9_5 {
  padding-left: 9.5rem;
}

.pd-l-9_75 {
  padding-left: 9.75rem;
}

.pd-l-10 {
  padding-left: 10rem;
}

.wrapper {
  width: 100%;
  max-width: 100%;
}

.content-wrapper {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}

.row {
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.button {
  border: 1px solid #610c04;
  border-radius: 8px;
  padding: 7px 1.5rem;
  font-family: "alegreyasans-medium", Helvetica, Arial, sans-serif !important;
  font-size: 1.2em;
  color: #610c04;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}
.button:hover {
  color: #ffffff;
  background: #610c04;
  text-decoration: none;
}

.white-outline-button {
  border: 1px solid #ffffff;
  color: #ffffff !important;
  background: none;
}
.white-outline-button:hover {
  color: #610c04 !important;
  background: #ffffff;
  text-decoration: none;
}

.cta-button {
  border: none;
  background: #f68019;
  color: #ffffff !important;
}
.cta-button:hover {
  color: #ffffff !important;
  background: #ff671f;
  text-decoration: none;
}

/*


.close-button {
    border: none;
    color: $white-color-ev !important;
    padding: 5px 15px;
    border-radius: 5px;
    background: $error-color-ev;


    &:hover {
        border: none;
        color: $white-color-ev !important;
        background: lighten($error-color-ev, 5%);
        text-decoration: none;
    }
}

.add-button {
    border-radius: 7px;
    padding: 6px 15px;
    background-color: darken($base-color-ev, 10%);
    color: $white-color-ev;
    border: none;
}

.primary-button {
    border-radius: 7px;
    padding: 10px 3rem;
    background-color: darken($secondary-color-ev, 10%);
    color: $white-color-ev !important;
    border: none;

    &:hover {
        color: $white-color-ev !important;
        background: $dark-secondary-color-ev;
        text-decoration: none;
    }
}

.secondary-button {
    border-radius: 7px;
    padding: 10px 2rem;
    background-color: darken($base-color-ev, 10%);
    color: $white-color-ev !important;
    border: none;

    &:hover {
        color: $white-color-ev !important;
        background: $base-color-ev;
        text-decoration: none;
    }
}
*/
.thumbnail-img-preview {
  max-width: 400px;
  margin: 2rem auto 3rem;
}

input[type=text],
input[type=email],
input[type=password],
input[type=date],
input[type=number],
textarea {
  border: 1px solid #8991a0;
  display: block;
  width: 100%;
  padding: 0.4rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 1em;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=date]:focus,
input[type=number]:focus,
textarea:focus {
  outline: none;
  border-color: #c31808;
  box-shadow: 0 0 2px #610c04;
}

.elabs-form-label {
  display: block;
}

/*select.elabs-form-select {
    border: 1px solid $mid-gray-color-ev;
    display: block;
    width: 100%;
    padding: 0.45rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 1em;
    &:focus {
        outline: none;
        border-color: lighten($base, 20%);
        box-shadow: 0 0 2px $base;
    }

    !** {
        padding: 1rem;
    }*!
}*/
.elabs-form-check-wrap {
  display: flex;
  align-items: center;
}

.elabs-form-check {
  display: flex;
  align-items: center;
  margin: 0 3rem 1rem 0;
}

.elabs-form-check-input[type=checkbox], .elabs-form-check-input[type=radio] {
  cursor: pointer;
}
.elabs-form-check-input[type=checkbox]:focus, .elabs-form-check-input[type=radio]:focus {
  outline: none;
  border-color: #c31808;
  box-shadow: 0 0 2px #610c04;
}
.elabs-form-check-input[type=checkbox]:checked, .elabs-form-check-input[type=radio]:checked {
  background-color: #610c04;
  border-color: #610c04;
}

.elabs-form-check-input[type=checkbox] {
  width: 1.2em;
  height: 1.2em;
}

.elabs-form-check-input[type=radio] {
  display: block;
  margin: 0;
  width: 1.3em;
  height: 1.3em;
}

.elabs-form-check-label {
  display: block;
  padding-left: 0.5rem;
}

.elabs-form-group {
  display: block;
  width: 100%;
}

.elabs-form-group-row {
  display: block;
}

.elabs-invalid-feedback {
  color: #d41010;
}

#password-input-wrap {
  position: relative;
}
#password-input-wrap #password-icon {
  position: absolute;
  display: inline-block;
  color: #1a202a;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;
  padding: 0 0.2rem;
}

@media screen and (min-width: 768px) {
  .elabs-form-group-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.5rem;
  }
  .elabs-form-group-row.inputs-1 .elabs-form-group {
    flex-basis: 100%;
  }
  .elabs-form-group-row.inputs-2 .elabs-form-group {
    flex-basis: 48%;
  }
  .elabs-form-group-row.inputs-3 .elabs-form-group {
    flex-basis: 32%;
  }
  .elabs-form-group-row.inputs-4 .elabs-form-group {
    flex-basis: 24%;
  }
  .elabs-form-group {
    display: block;
    width: 100%;
  }
}
/* The container must be positioned relative: */
.elabs-form-select-wrap {
  position: relative;
}

.elabs-form-select-wrap select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: #ffffff;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 20px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #1a202a transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #1a202a transparent;
  top: 12px;
}

/* style the items (options), including the selected item: */
.select-items div, .select-selected {
  color: #1a202a;
  cursor: pointer;
}

.select-selected {
  border: 1px solid #8991a0;
  padding: 0.45rem 0.5rem;
  border-radius: 3px;
}

.select-items div {
  padding: 0.3rem 0.5rem;
  background-color: #ffffff;
  border-radius: 0;
}

/* Style items (options): */
.select-items {
  position: absolute;
  border: 1px solid #f9f9f9;
  border-radius: 8px;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}
.select-items.mid-viewport {
  top: unset;
  bottom: 100%;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover {
  background-color: #921206;
  color: #ffffff;
}

.same-as-selected {
  background-color: #610c04 !important;
  color: #ffffff !important;
}
.same-as-selected:hover {
  background-color: #610c04 !important;
  color: #ffffff !important;
}

/*

.s-hidden {
    visibility:hidden;
    padding-right:10px;
}
.select {
    cursor:pointer;
    display:inline-block;
    position:relative;
    font:normal 11px/22px Arial, Sans-Serif;
    color:black;
    border:1px solid #ccc;
}
.styledSelect {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:white;
    padding:0 10px;
    font-weight:bold;
}
.styledSelect:after {
    content:"";
    width:0;
    height:0;
    //border:5px solid transparent;
    border-color:black transparent transparent transparent;
    position:absolute;
    top:9px;
    right:6px;
}
.styledSelect:active, .styledSelect.active {
    background-color:#eee;
}
.options {
    display:none;
    position:absolute;
    top:100%;
    right:0;
    left:0;
    z-index:999;
    margin:0 0;
    padding:0 0;
    list-style:none;
    border:1px solid #ccc;
    background-color:white;
    -webkit-box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow:0 1px 2px rgba(0, 0, 0, 0.2);
}
.options li {
    //padding:0 6px;
    margin:0 0;
    padding:0.5rem 10px;
}
.options li:hover {
    background-color:$base;
    color:white;
}
*/
.social-share-icons {
  text-align: center;
  margin-top: 3rem;
  background-color: #ffffff;
  padding: 2rem 1rem;
}
.social-share-icons #social-links {
  width: 100%;
}
.social-share-icons ul {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.social-share-icons ul a {
  font-size: 1.5em;
}

div.top-header-info {
  background-color: #260101;
  width: 100%;
  display: block;
  padding: 5px;
}
div.top-header-info .content-wrapper {
  padding: 0;
  display: block;
  text-align: center;
}
div.top-header-info p {
  color: #ffffff;
  margin-right: 0;
}
div.top-header-info p i {
  color: #f68019;
  margin-right: 5px;
}

.header_image {
  display: block;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  max-width: 1280px;
}
.header_image img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

div.after-head {
  margin-top: 50px;
  display: block;
}

@media screen and (min-width: 768px) {
  div.top-header-info .content-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  div.top-header-info p {
    margin-right: 40px;
  }
  div.top-header-info p i {
    margin-right: 7px;
  }
}
header {
  background-color: #1a202a;
  height: 50px;
  /*.nav-wrapper {
      max-width: 1170px;
      margin: 0 auto;
  }*/
}

.top-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  color: #ffffff;
  border: none;
  transition: 0.5s;
  overflow: visible;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 50px;
}

.top-nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 100%;
  line-height: 50px;
}

.nav-logo {
  padding: 0 10px 0 10px;
  margin: 0;
  width: 200px;
  height: 50px;
  overflow: hidden;
  max-width: 200px;
  color: #f68019;
  font-size: 20px;
}
.nav-logo:hover {
  color: #790f05;
}
.nav-logo .logo-icon {
  margin-right: 5px;
  padding-left: 0.5rem;
}
.nav-logo .logo-icon img {
  max-width: 25px;
  width: 25px !important;
  margin: 0 auto;
}
.nav-logo .logo-text {
  color: #f68019;
  margin-left: 0.5rem;
}
.nav-logo .logo-text img {
  max-width: 100px;
  width: 100px !important;
  margin: 0 auto;
}
.nav-logo .logo-text:hover {
  color: #ff7839;
}

.nav-hamburger {
  border: none;
  padding: 7px 20px 7px;
  background: none;
  outline: none;
  margin-right: 10px;
  line-height: 30px;
}
.nav-hamburger .mdi {
  color: #ffffff;
  font-size: 20px;
}
.nav-hamburger:hover {
  outline: none;
  box-shadow: none;
  background-color: #610c04;
  color: #ffffff;
}
.nav-hamburger:hover i.mdi {
  color: #ffffff;
}

.top-navbar-expanded {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50px;
  right: 0;
  left: 260px;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
  z-index: 1000;
}

.sidebar-menu-hamburger {
  display: none;
}

.expanded-top-navbar-ul {
  margin: 0;
  padding: 0;
  display: block;
}

.top-nav-item {
  text-align: left;
}

.top-nav-link {
  padding: 1rem 2rem;
  display: block;
  font-size: 1em;
  font-family: "alegreyasans-regular", Helvetica, Arial, sans-serif !important;
  line-height: 1;
  z-index: 1000;
  cursor: pointer;
}
.top-nav-link:hover {
  color: #ffffff;
}
.top-nav-link:active, .top-nav-link:focus {
  color: #ffffff;
  background-color: #610c04;
}
.top-nav-link.selected {
  color: #ffffff;
  background-color: #610c04;
}
.top-nav-link.nav-dropdown-header {
  color: #1a202a;
}
.top-nav-link img {
  width: 30px;
}
.top-nav-link .mdi {
  font-size: 20px;
}

.toplevel-top-nav-item {
  border-bottom: 1px solid #999999;
  color: #8991a0;
  background-color: #1a202a;
}
.toplevel-top-nav-item:active, .toplevel-top-nav-item:focus {
  color: #ffffff;
  background-color: #610c04;
}
.toplevel-top-nav-item.selected {
  color: #ffffff;
  background-color: #610c04;
}
.toplevel-top-nav-item .menu-name {
  padding-left: 0.5rem;
}

.top-nav-dropdown-ul {
  color: #ffffff;
  background-color: #242c3a;
  max-height: 0;
  transition: max-height 0.1s ease-out;
  overflow: hidden;
}

.top-nav-dropdown-link {
  color: #8991a0;
}
.top-nav-dropdown-link .mdi {
  padding-right: 0.5rem;
}
.top-nav-dropdown-link:active, .top-nav-dropdown-link:focus {
  color: #ffffff;
}

@media screen and (min-width: 992px) {
  .top-nav-header {
    display: flex;
    width: 197px;
    flex-basis: 197px;
  }
  .top-nav-header .nav-hamburger {
    display: none;
  }
  .top-navbar-expanded {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    max-height: 50px;
    overflow: visible;
  }
  .sidebar-menu-hamburger {
    display: block;
  }
  .sidebar-menu-hamburger a {
    display: block;
  }
  .sidebar-menu-hamburger .mdi {
    color: #ffffff;
    font-size: 20px;
  }
  .expanded-top-navbar-ul {
    display: flex;
  }
  .top-nav-link {
    padding: 1rem 1rem;
  }
  .top-nav-link img {
    width: 26px;
  }
  .top-nav-link .mdi {
    font-size: 22px;
  }
  .toplevel-top-nav-item {
    border-bottom: none;
    padding: 0 1rem;
    line-height: 45px;
  }
  .toplevel-top-nav-item .menu-name {
    display: none;
  }
  .top-nav-dropdown-ul {
    position: absolute;
    top: 50px;
    max-height: 0;
    transition: max-height 0.1s ease-out;
    overflow: hidden;
    z-index: 1000;
  }
  .top-nav-dropdown-ul.right {
    left: -170px;
    right: 0;
  }
  .top-nav-dropdown-ul.opened .top-nav-dropdown-link {
    padding: 1rem 1rem;
    /*&:last-of-type {
        padding-bottom: 2rem;
    }*/
  }
  .top-nav-dropdown-ul.opened li:last-of-type a {
    padding-bottom: 2rem;
  }
}
@media screen and (min-width: 1366px) {
  .toplevel-top-nav-item .menu-name {
    display: inline-block;
  }
}
.left-sidebar {
  position: absolute;
  top: 50px;
  left: 0;
  width: 70px;
  background-color: #1a202a;
  max-height: calc(100vh - 50px);
  transition: width 0.1s ease-out;
  overflow-y: scroll;
  font-family: "alegreyasans-regular", Helvetica, Arial, sans-serif !important;
  font-size: 0.9em;
}

#sidebar-menu-arrow {
  border: none;
  padding: 7px 25px 7px 20px;
  background: none;
  outline: none;
  line-height: 30px;
  width: 100%;
  opacity: 0.6;
}
#sidebar-menu-arrow span {
  display: block;
}
#sidebar-menu-arrow .mdi {
  color: #ffffff;
  font-size: 18px;
  display: block;
}
#sidebar-menu-arrow .mdi.mdi-arrow-right-bold {
  text-align: left;
}
#sidebar-menu-arrow .mdi.mdi-arrow-left-bold {
  text-align: right;
}
#sidebar-menu-arrow:hover {
  outline: none;
  box-shadow: none;
  color: #ffffff;
  opacity: 1;
}
#sidebar-menu-arrow:hover i.mdi {
  color: #ffffff;
}

.sidebar-nav-ul {
  padding-left: 0;
}

.sidebar-link {
  color: #fff;
  padding: 8px 15px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  line-height: 25px;
  opacity: 0.6;
}
.sidebar-link i {
  font-style: normal;
  width: 35px;
  line-height: 25px;
  font-size: 23px;
  color: #fff;
  display: inline-block;
  text-align: center;
}
.sidebar-link .hide-menu {
  display: none;
  width: 100%;
  justify-content: space-between;
}
.sidebar-link:active, .sidebar-link:focus {
  background-color: #610c04;
  color: white;
}

.sidebar-module-header-wrap {
  padding: 0.7rem 0 0.5rem;
  color: #610c04;
}
.sidebar-module-header-wrap .module-header-name {
  padding-left: 1.5rem;
}
.sidebar-module-header-wrap .sidebar-module-divider {
  border-bottom: 1px solid #610c04;
  width: 80%;
  margin: 0 auto;
}

.sidebar-dropdown-ul {
  overflow: hidden;
  background-color: #242c3a;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.toplevel-sidebar-item:hover {
  opacity: 1;
  color: #ffffff;
}

.sidebar-dropdown-item:hover .sidebar-dropdown-link {
  opacity: 1;
  color: #ffffff;
}

.toplevel-sidebar-item.selected {
  opacity: 1;
  background-color: #610c04;
}

.sidebar-dropdown-item {
  padding-left: 1rem;
}
.sidebar-dropdown-item.selected .sidebar-dropdown-link {
  opacity: 1;
}

@media screen and (min-width: 992px) {
  #sidebar-menu-arrow {
    display: none;
  }
}
#admin-main {
  padding-left: 70px;
  transition: padding-left 0.1s ease-out;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.admin-main-page-content-wrapper {
  min-height: calc(100vh - 150px);
  padding: 2rem 1rem;
  width: 100%;
}

.add-button-wrap {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

footer {
  height: 100px;
}

.footer-sidebar-wrapper {
  padding: 0.5rem 1rem;
}

.admin-footer {
  width: 100%;
  background-color: #1a202a;
  color: #f3f3f3;
  text-align: center;
}
.admin-footer .powered_by_link {
  color: #ff671f;
}

#admin-login-page-wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #1a202a;
  color: #ffffff;
  padding: 2rem;
}

.login-form-wrapper {
  max-width: 400px;
  margin: 5rem auto;
}
.login-form-wrapper .login-logo-wrap {
  width: 200px;
  margin: 2rem auto;
  overflow: hidden;
}
.login-form-wrapper .login-logo-wrap img {
  width: 100%;
  max-width: 100%;
}