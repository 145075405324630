/* Margin All */
.mg-0 { margin: 0; }
.mg-0_25 { margin: 0.25rem; }
.mg-0_5 { margin: 0.5rem; }
.mg-0_75 { margin: 0.75rem; }
.mg-1 { margin: 1rem; }
.mg-1_25 { margin: 1.25rem; }
.mg-1_5 { margin: 1.5rem; }
.mg-1_75 { margin: 1.75rem; }
.mg-2 { margin: 2rem; }
.mg-2_25 { margin: 2.25rem; }
.mg-2_5 { margin: 2.5rem; }
.mg-2_75 { margin: 2.75rem; }
.mg-3 { margin: 3rem; }
.mg-3_25 { margin: 3.25rem; }
.mg-3_5 { margin: 3.5rem;}
.mg-3_75 { margin: 3.75rem;}
.mg-4 { margin: 4rem;}
.mg-4_25 { margin: 4.25rem; }
.mg-4_5 { margin: 4.5rem; }
.mg-4_75 { margin: 4.75rem; }
.mg-5 { margin: 5rem; }
.mg-5_25 { margin: 5.25rem; }
.mg-5_5 { margin: 5.5rem; }
.mg-5_75 { margin: 5.75rem; }
.mg-6 { margin: 6rem; }
.mg-6_25 { margin: 6.25rem; }
.mg-6_5 { margin: 6.5rem; }
.mg-6_75 { margin: 6.75rem; }
.mg-7 { margin: 7rem; }
.mg-7_25 { margin: 7.25rem; }
.mg-7_5 { margin: 7.5rem; }
.mg-7_75 { margin: 7.75rem; }
.mg-8 { margin: 8rem; }
.mg-8_25 { margin: 8.25rem; }
.mg-8_5 { margin: 8.5rem; }
.mg-8_75 { margin: 8.75rem; }
.mg-9 { margin: 9rem; }
.mg-9_25 { margin: 9.25rem; }
.mg-9_5 { margin: 9.5rem; }
.mg-9_75 { margin: 9.75rem; }
.mg-10 { margin: 10rem; }



/* Margin Vertical */
.mg-y-0_25 { margin-top: 0.25rem; margin-bottom: 0.25rem;}
.mg-y-0_5 { margin-top: 0.5rem; margin-bottom: 0.5rem; }
.mg-y-0_75 { margin-top: 0.75rem; margin-bottom: 0.75rem; }
.mg-y-1 { margin-top: 1rem; margin-bottom: 1rem; }
.mg-y-1_25 { margin-top: 1.25rem; margin-bottom: 1.25rem; }
.mg-y-1_5 { margin-top: 1.5rem;  margin-bottom: 1.5rem; }
.mg-y-1_75 { margin-top: 1.75rem; margin-bottom: 1.75rem; }
.mg-y-2 { margin-top: 2rem; margin-bottom: 2rem; }
.mg-y-2_25 { margin-top: 2.25rem; margin-bottom: 2.25rem; }
.mg-y-2_5 { margin-top: 2.5rem;  margin-bottom: 2.5rem; }
.mg-y-2_75 { margin-top: 2.75rem; margin-bottom: 2.75rem; }
.mg-y-3 { margin-top: 3rem; margin-bottom: 3rem; }
.mg-y-3_25 { margin-top: 3.25rem; margin-bottom: 3.25rem; }
.mg-y-3_5 { margin-top: 3.5rem;  margin-bottom: 3.5rem; }
.mg-y-3_75 { margin-top: 3.75rem; margin-bottom: 3.75rem;}
.mg-y-4 { margin-top: 4rem; margin-bottom: 4rem; }
.mg-y-4_25 { margin-top: 4.25rem; margin-bottom: 4.25rem; }
.mg-y-4_5 { margin-top: 4.5rem;  margin-bottom: 4.5rem; }
.mg-y-4_75 { margin-top: 4.75rem; margin-bottom: 4.75rem; }
.mg-y-5 { margin-top: 5rem; margin-bottom: 5rem; }
.mg-y-5_25 { margin-top: 5.25rem; margin-bottom: 5.25rem; }
.mg-y-5_5 { margin-top: 5.5rem;  margin-bottom: 5.5rem; }
.mg-y-5_75 { margin-top: 5.75rem; margin-bottom: 5.75rem; }
.mg-y-6 { margin-top: 6rem; margin-bottom: 6rem; }
.mg-y-6_25 { margin-top: 6.25rem; margin-bottom: 6.25rem; }
.mg-y-6_5 { margin-top: 6.5rem; margin-bottom: 6.5rem; }
.mg-y-6_75 { margin-top: 6.75rem; margin-bottom: 6.75rem; }
.mg-y-7 { margin-top: 7rem; margin-bottom: 7rem; }
.mg-y-7_25 { margin-top: 7.25rem; margin-bottom: 7.25rem; }
.mg-y-7_5 { margin-top: 7.5rem;  margin-bottom: 7.5rem; }
.mg-y-7_75 { margin-top: 7.75rem; margin-bottom: 7.75rem; }
.mg-y-8 { margin-top: 8rem; margin-bottom: 8rem; }
.mg-y-8_25 { margin-top: 8.25rem; margin-bottom: 8.25rem; }
.mg-y-8_5 { margin-top: 8.5rem;  margin-bottom: 8.5rem; }
.mg-y-8_75 { margin-top: 8.75rem; margin-bottom: 8.75rem; }
.mg-y-9 { margin-top: 9rem; margin-bottom: 9rem; }
.mg-y-9_25 { margin-top: 9.25rem; margin-bottom: 9.25rem; }
.mg-y-9_5 { margin-top: 9.5rem; margin-bottom: 9.5rem; }
.mg-y-9_75 { margin-top: 9.75rem; margin-bottom: 9.75rem; }
.mg-y-10 { margin-top: 10rem; margin-bottom: 10rem; }



/* Margin Horizontal */
.mg-x-0_25 { margin-left: 0.25rem; margin-right: 0.25rem; }
.mg-x-0_5 { margin-left: 0.5rem; margin-right: 0.5rem;}
.mg-x-0_75 { margin-left: 0.75rem; margin-right: 0.75rem; }
.mg-x-1 { margin-left: 1rem; margin-right: 1rem; }
.mg-x-1_25 { margin-left: 1.25rem; margin-right: 1.25rem; }
.mg-x-1_5 { margin-left: 1.5rem;  margin-right: 1.5rem; }
.mg-x-1_75 { margin-left: 1.75rem; margin-right: 1.75rem; }
.mg-x-2 { margin-left: 2rem; margin-right: 2rem; }
.mg-x-2_25 { margin-left: 2.25rem; margin-right: 2.25rem; }
.mg-x-2_5 { margin-left: 2.5rem; margin-right: 2.5rem; }
.mg-x-2_75 { margin-left: 2.75rem; margin-right: 2.75rem; }
.mg-x-3 { margin-left: 3rem; margin-right: 3rem; }
.mg-x-3_25 { margin-left: 3.25rem; margin-right: 3.25rem; }
.mg-x-3_5 { margin-left: 3.5rem; margin-right: 3.5rem; }
.mg-x-3_75 { margin-left: 3.75rem; margin-right: 3.75rem; }
.mg-x-4 { margin-left: 4rem; margin-right: 4rem; }
.mg-x-4_25 { margin-left: 4.25rem; margin-right: 4.25rem; }
.mg-x-4_5 { margin-left: 4.5rem; margin-right: 4.5rem; }
.mg-x-4_75 { margin-left: 4.75rem; margin-right: 4.75rem; }
.mg-x-5 { margin-left: 5rem; margin-right: 5rem; }
.mg-x-5_25 { margin-left: 5.25rem; margin-right: 5.25rem; }
.mg-x-5_5 { margin-left: 5.5rem; margin-right: 5.5rem; }
.mg-x-5_75 { margin-left: 5.75rem; margin-right: 5.75rem; }
.mg-x-6 { margin-left: 6rem; margin-right: 6rem; }
.mg-x-6_25 { margin-left: 6.25rem; margin-right: 6.25rem; }
.mg-x-6_5 { margin-left: 6.5rem; margin-right: 6.5rem; }
.mg-x-6_75 { margin-left: 6.75rem; margin-right: 6.75rem; }
.mg-x-7 { margin-left: 7rem; margin-right: 7rem; }
.mg-x-7_25 { margin-left: 7.25rem; margin-right: 7.25rem; }
.mg-x-7_5 { margin-left: 7.5rem; margin-right: 7.5rem; }
.mg-x-7_75 { margin-left: 7.75rem; margin-right: 7.75rem; }
.mg-x-8 { margin-left: 8rem; margin-right: 8rem; }
.mg-x-8_25 { margin-left: 8.25rem; margin-right: 8.25rem; }
.mg-x-8_5 { margin-left: 8.5rem; margin-right: 8.5rem; }
.mg-x-8_75 { margin-left: 8.75rem; margin-right: 8.75rem; }
.mg-x-9 { margin-left: 9rem; margin-right: 9rem; }
.mg-x-9_25 { margin-left: 9.25rem; margin-right: 9.25rem; }
.mg-x-9_5 { margin-left: 9.5rem; margin-right: 9.5rem; }
.mg-x-9_75 { margin-left: 9.75rem; margin-right: 9.75rem; }
.mg-x-10 { margin-left: 10rem; margin-right: 10rem; }



/* Margin Top */
.mg-t-0_25 { margin-top: 0.25rem; }
.mg-t-0_5 { margin-top: 0.5rem; }
.mg-t-0_75 { margin-top: 0.75rem; }
.mg-t-1 { margin-top: 1rem; }
.mg-t-1_25 { margin-top: 1.25rem; }
.mg-t-1_5 { margin-top: 1.5rem; }
.mg-t-1_75 { margin-top: 1.75rem; }
.mg-t-2 { margin-top: 2rem; }
.mg-t-2_25 { margin-top: 2.25rem; }
.mg-t-2_5 { margin-top: 2.5rem; }
.mg-t-2_75 { margin-top: 2.75rem; }
.mg-t-3 { margin-top: 3rem; }
.mg-t-3_25 { margin-top: 3.25rem; }
.mg-t-3_5 { margin-top: 3.5rem; }
.mg-t-3_75 { margin-top: 3.75rem;}
.mg-t-4 { margin-top: 4rem; }
.mg-t-4_25 { margin-top: 4.25rem; }
.mg-t-4_5 { margin-top: 4.5rem; }
.mg-t-4_75 { margin-top: 4.75rem; }
.mg-t-5 { margin-top: 5rem; }
.mg-t-5_25 { margin-top: 5.25rem; }
.mg-t-5_5 { margin-top: 5.5rem; }
.mg-t-5_75 { margin-top: 5.75rem; }
.mg-t-6 { margin-top: 6rem; }
.mg-t-6_25 { margin-top: 6.25rem; }
.mg-t-6_5 { margin-top: 6.5rem; }
.mg-t-6_75 { margin-top: 6.75rem; }
.mg-t-7 { margin-top: 7rem; }
.mg-t-7_25 { margin-top: 7.25rem; }
.mg-t-7_5 { margin-top: 7.5rem; }
.mg-t-7_75 { margin-top: 7.75rem; }
.mg-t-8 { margin-top: 8rem; }
.mg-t-8_25 { margin-top: 8.25rem; }
.mg-t-8_5 { margin-top: 8.5rem; }
.mg-t-8_75 { margin-top: 8.75rem; }
.mg-t-9 { margin-top: 9rem; }
.mg-t-9_25 { margin-top: 9.25rem; }
.mg-t-9_5 { margin-top: 9.5rem; }
.mg-t-9_75 { margin-top: 9.75rem; }
.mg-t-10 { margin-top: 10rem; }





/* Margin Right */
.mg-r-0_25 { margin-right: 0.25rem; }
.mg-r-0_5 { margin-right: 0.5rem; }
.mg-r-0_75 { margin-right: 0.75rem; }
.mg-r-1 { margin-right: 1rem; }
.mg-r-1_25 { margin-right: 1.25rem; }
.mg-r-1_5 { margin-right: 1.5rem; }
.mg-r-1_75 { margin-right: 1.75rem; }
.mg-r-2 { margin-right: 2rem; }
.mg-r-2_25 { margin-right: 2.25rem; }
.mg-r-2_5 { margin-right: 2.5rem; }
.mg-r-2_75 { margin-right: 2.75rem; }
.mg-r-3 { margin-right: 3rem; }
.mg-r-3_25 { margin-right: 3.25rem; }
.mg-r-3_5 { margin-right: 3.5rem; }
.mg-r-3_75 { margin-right: 3.75rem;}
.mg-r-4 { margin-right: 4rem; }
.mg-r-4_25 { margin-right: 4.25rem; }
.mg-r-4_5 { margin-right: 4.5rem; }
.mg-r-4_75 { margin-right: 4.75rem; }
.mg-r-5 { margin-right: 5rem; }
.mg-r-5_25 { margin-right: 5.25rem; }
.mg-r-5_5 { margin-right: 5.5rem; }
.mg-r-5_75 { margin-right: 5.75rem; }
.mg-r-6 { margin-right: 6rem; }
.mg-r-6_25 { margin-right: 6.25rem; }
.mg-r-6_5 { margin-right: 6.5rem; }
.mg-r-6_75 { margin-right: 6.75rem; }
.mg-r-7 { margin-right: 7rem; }
.mg-r-7_25 { margin-right: 7.25rem; }
.mg-r-7_5 { margin-right: 7.5rem; }
.mg-r-7_75 { margin-right: 7.75rem; }
.mg-r-8 { margin-right: 8rem; }
.mg-r-8_25 { margin-right: 8.25rem; }
.mg-r-8_5 { margin-right: 8.5rem; }
.mg-r-8_75 { margin-right: 8.75rem; }
.mg-r-9 { margin-right: 9rem; }
.mg-r-9_25 { margin-right: 9.25rem; }
.mg-r-9_5 { margin-right: 9.5rem; }
.mg-r-9_75 { margin-right: 9.75rem; }
.mg-r-10 { margin-right: 10rem; }




/* Margin Bottom */
.mg-b-0_25 { margin-bottom: 0.25rem; }
.mg-b-0_5 { margin-bottom: 0.5rem; }
.mg-b-0_75 { margin-bottom: 0.75rem; }
.mg-b-1 { margin-bottom: 1rem; }
.mg-b-1_25 { margin-bottom: 1.25rem; }
.mg-b-1_5 { margin-bottom: 1.5rem; }
.mg-b-1_75 { margin-bottom: 1.75rem; }
.mg-b-2 { margin-bottom: 2rem; }
.mg-b-2_25 { margin-bottom: 2.25rem; }
.mg-b-2_5 { margin-bottom: 2.5rem; }
.mg-b-2_75 { margin-bottom: 2.75rem; }
.mg-b-3 { margin-bottom: 3rem; }
.mg-b-3_25 { margin-bottom: 3.25rem; }
.mg-b-3_5 { margin-bottom: 3.5rem; }
.mg-b-3_75 { margin-bottom: 3.75rem;}
.mg-b-4 { margin-bottom: 4rem; }
.mg-b-4_25 { margin-bottom: 4.25rem; }
.mg-b-4_5 { margin-bottom: 4.5rem; }
.mg-b-4_75 { margin-bottom: 4.75rem; }
.mg-b-5 { margin-bottom: 5rem; }
.mg-b-5_25 { margin-bottom: 5.25rem; }
.mg-b-5_5 { margin-bottom: 5.5rem; }
.mg-b-5_75 { margin-bottom: 5.75rem; }
.mg-b-6 { margin-bottom: 6rem; }
.mg-b-6_25 { margin-bottom: 6.25rem; }
.mg-b-6_5 { margin-bottom: 6.5rem; }
.mg-b-6_75 { margin-bottom: 6.75rem; }
.mg-b-7 { margin-bottom: 7rem; }
.mg-b-7_25 { margin-bottom: 7.25rem; }
.mg-b-7_5 { margin-bottom: 7.5rem; }
.mg-b-7_75 { margin-bottom: 7.75rem; }
.mg-b-8 { margin-bottom: 8rem; }
.mg-b-8_25 { margin-bottom: 8.25rem; }
.mg-b-8_5 { margin-bottom: 8.5rem; }
.mg-b-8_75 { margin-bottom: 8.75rem; }
.mg-b-9 { margin-bottom: 9rem; }
.mg-b-9_25 { margin-bottom: 9.25rem; }
.mg-b-9_5 { margin-bottom: 9.5rem; }
.mg-b-9_75 { margin-bottom: 9.75rem; }
.mg-b-10 { margin-bottom: 10rem; }



/* Margin Left */
.mg-l-0_25 { margin-left: 0.25rem; }
.mg-l-0_5 { margin-left: 0.5rem; }
.mg-l-0_75 { margin-left: 0.75rem; }
.mg-l-1 { margin-left: 1rem; }
.mg-l-1_25 { margin-left: 1.25rem; }
.mg-l-1_5 { margin-left: 1.5rem; }
.mg-l-1_75 { margin-left: 1.75rem; }
.mg-l-2 { margin-left: 2rem; }
.mg-l-2_25 { margin-left: 2.25rem; }
.mg-l-2_5 { margin-left: 2.5rem; }
.mg-l-2_75 { margin-left: 2.75rem; }
.mg-l-3 { margin-left: 3rem; }
.mg-l-3_25 { margin-left: 3.25rem; }
.mg-l-3_5 { margin-left: 3.5rem; }
.mg-l-3_75 { margin-left: 3.75rem;}
.mg-l-4 { margin-left: 4rem; }
.mg-l-4_25 { margin-left: 4.25rem; }
.mg-l-4_5 { margin-left: 4.5rem; }
.mg-l-4_75 { margin-left: 4.75rem; }
.mg-l-5 { margin-left: 5rem; }
.mg-l-5_25 { margin-left: 5.25rem; }
.mg-l-5_5 { margin-left: 5.5rem; }
.mg-l-5_75 { margin-left: 5.75rem; }
.mg-l-6 { margin-left: 6rem; }
.mg-l-6_25 { margin-left: 6.25rem; }
.mg-l-6_5 { margin-left: 6.5rem; }
.mg-l-6_75 { margin-left: 6.75rem; }
.mg-l-7 { margin-left: 7rem; }
.mg-l-7_25 { margin-left: 7.25rem; }
.mg-l-7_5 { margin-left: 7.5rem; }
.mg-l-7_75 { margin-left: 7.75rem; }
.mg-l-8 { margin-left: 8rem; }
.mg-l-8_25 { margin-left: 8.25rem; }
.mg-l-8_5 { margin-left: 8.5rem; }
.mg-l-8_75 { margin-left: 8.75rem; }
.mg-l-9 { margin-left: 9rem; }
.mg-l-9_25 { margin-left: 9.25rem; }
.mg-l-9_5 { margin-left: 9.5rem; }
.mg-l-9_75 { margin-left: 9.75rem; }
.mg-l-10 { margin-left: 10rem; }
