#admin-main {
    padding-left: 70px;
    transition: padding-left 0.1s ease-out;
    @include display-flex(space-between, flex-start);
    flex-direction: column;
}

.admin-main-page-content-wrapper{
    // Min height = 100vh - (top-nav height + footer height)
    min-height: calc(100vh - 150px);

    padding: 2rem 1rem;
    width: 100%;
}

.add-button-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

footer {
    height: 100px;
}
