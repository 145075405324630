/** Text Position */
.txt-center {
    text-align: center;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right;
}


/** Text Color */
.txt-white {
    color: $white-color-ev;
}

.txt-disabled {
    color: $mid-gray-color-ev;
}

.txt-base {
    color: $base-color-ev;
}

.txt-base-darken {
    color: $dark-base-color-ev;
}

.txt-secondary {
    color: $secondary-color-ev;
}

.txt-secondary-darken {
    color: $dark-secondary-color-ev;
}

.txt-accent {
    color: $accent-color-ev;
}

.txt-accent-darken {
    color: $dark-accent-color-ev;
}

.dark-thick-heading {
    margin-right: 0.5rem;
    color: $dark-gray-color-ev;
}

.white-thick-heading {
    margin-right: 0.5rem;
    color: $white-color-ev;
}

.base-thin-heading{
    color: $base-color-ev;
    font-family: $body-font-regular;
}
