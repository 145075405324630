.left-sidebar {
    position: absolute;
    top: 50px;
    left: 0;
    width: 70px;
    background-color: $dark-gray-color-ev;
    //bottom: 0;
    max-height: calc(100vh - 50px);
    transition: width 0.1s ease-out;
    overflow-y: scroll;
    font-family: $body-font-regular;
    font-size: 0.9em;
}

#sidebar-menu-arrow {
    border: none;
    padding: 7px 25px 7px 20px;
    background: none;
    outline: none;
    line-height: 30px;
    width: 100%;
    opacity: 0.6;

    span {
        display: block;
    }
    .mdi {
        color: $white-color-ev;
        font-size: 18px;
        display: block;
        &.mdi-arrow-right-bold {
            text-align: left;
        }
        &.mdi-arrow-left-bold {
            text-align: right;
        }
    }
    &:hover {
        outline: none;
        box-shadow: none;
        //background-color: $base;
        color: $white-color-ev;
        opacity: 1;
        i.mdi {
            color: $white-color-ev;
        }
    }
}

.sidebar-nav-ul {
    padding-left: 0;
}

.sidebar-link {
    color: #fff;
    padding: 8px 15px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    line-height: 25px;
    opacity: 0.6;

    i {
        font-style: normal;
        width: 35px;
        line-height: 25px;
        font-size: 23px;
        color: #fff;
        display: inline-block;
        text-align: center;
    }

    .hide-menu {
        display: none;
        width: 100%;
        justify-content: space-between;
    }

    &:active, &:focus {
        background-color: $base-color-ev;
        color: white;
    }
}

.sidebar-module-header-wrap {
    padding: 0.7rem 0 0.5rem;
    color: $base-color-ev;

    .module-header-name {
        padding-left: 1.5rem;
    }

    .sidebar-module-divider {
        border-bottom: 1px solid $base-color-ev;
        width: 80%;
        margin: 0 auto;
    }
}

.sidebar-dropdown-ul {
    overflow: hidden;
    background-color: lighten($dark-gray-color-ev, 5%);

    max-height: 0;
    transition: max-height 0.2s ease-out;
}

.toplevel-sidebar-item:hover{
    opacity: 1;
    color: $white-color-ev;
}

.sidebar-dropdown-item:hover{
    .sidebar-dropdown-link {
        opacity: 1;
        color: $white-color-ev;
    }
}

.toplevel-sidebar-item{
    &.selected {
        opacity: 1;
        background-color: $base-color-ev;

    }
}

.sidebar-dropdown-item{
    padding-left: 1rem;
    &.selected {
        .sidebar-dropdown-link {
            opacity: 1;
        }
    }
}


@media screen and (min-width: 992px) {
    #sidebar-menu-arrow {
        display: none;
    }
}
