.card {
background-color: $white-color-ev;
border-radius: 10px;
box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
padding: 20px;
}

.alert {
margin: 1rem auto 0;
width: 90%;
max-width: 1170px;
padding: 0.5rem 1rem;
}


.breadcrumb-item {
font-size: 0.8em;
}


