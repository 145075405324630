html{
    position: relative;
}
body {
    background-color: $white-color-ev;
    min-width: 300px !important;
    position: relative;
}

p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

img {
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

ul {
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
}

hr {
    margin: 0.1rem 0;
}


a {
    text-decoration: none;
    color: $base-color-ev;

    &:hover {
        color: darken($base-color-ev, 10%);
        //font-weight: bold;
    }
}
