input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="number"],
textarea {
    border: 1px solid $mid-gray-color-ev;
    display: block;
    width: 100%;
    padding: 0.4rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 1em;
    &:focus {
        outline: none;
        border-color: lighten($base-color-ev, 20%);
        box-shadow: 0 0 2px $base-color-ev;
    }
}

.elabs-form-label {
    display: block;
}



/*select.elabs-form-select {
    border: 1px solid $mid-gray-color-ev;
    display: block;
    width: 100%;
    padding: 0.45rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 1em;
    &:focus {
        outline: none;
        border-color: lighten($base, 20%);
        box-shadow: 0 0 2px $base;
    }

    !** {
        padding: 1rem;
    }*!
}*/

.elabs-form-check-wrap{
    display: flex;
    align-items: center;
}

.elabs-form-check{
    display: flex;
    align-items: center;
    margin: 0 3rem 1rem 0;
}

.elabs-form-check-input[type=checkbox], .elabs-form-check-input[type=radio] {
    cursor: pointer;
    &:focus {
        outline: none;
        border-color: lighten($base-color-ev, 20%);
        box-shadow: 0 0 2px $base-color-ev;
    }
    &:checked{
        background-color: $base-color-ev;
        border-color: $base-color-ev;
    }
}

.elabs-form-check-input[type=checkbox] {
    width: 1.2em;
    height: 1.2em;
}

.elabs-form-check-input[type=radio] {
    display: block;
    margin: 0;
    width: 1.3em;
    height: 1.3em;
}

.elabs-form-check-label{
    display: block;
    padding-left: 0.5rem;
}

.elabs-form-group {
    display: block;
    width: 100%;
}
.elabs-form-group-row {
    display: block;
}
.elabs-invalid-feedback{
    color: $error-color-ev;
}

#password-input-wrap{
    position: relative;

    #password-icon {
        position: absolute;
        display: inline-block;
        color: $dark-gray-color-ev;
        right: 0.5rem;
        top: 0.5rem;
        cursor: pointer;
        padding: 0 0.2rem;
    }
}



@media screen and (min-width: 768px) {
    .elabs-form-group-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 0.5rem;

        &.inputs-1 {
            .elabs-form-group {
                flex-basis: 100%;
            }
        }

        &.inputs-2 {
            .elabs-form-group {
                flex-basis: 48%;
            }
        }

        &.inputs-3 {
            .elabs-form-group {
                flex-basis: 32%;
            }
        }
        &.inputs-4 {
            .elabs-form-group {
                flex-basis: 24%;
            }
        }
    }

    .elabs-form-group {
        display: block;
        width: 100%;
        //max-width: 400px;
    }

}
