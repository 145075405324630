.bg-white, .btn-white {
    background-color: $white-color-ev;
    color: $text-color-ev;
}

.bg-light-gray, .btn-light-gray {
    background-color: $light-gray-color-ev;
    color: $text-color-ev;
}

.bg-dark-gray, .btn-dark-gray {
    background-color: $dark-gray-color-ev;
    color: $white-color-ev;
}

.bg-base, .btn-base {
    background-color: $base-color-ev;
    color: $white-color-ev !important;
}

.bg-dark-base, .btn-dark-base {
    background-color: $dark-base-color-ev;
    color: $white-color-ev;
}

.bg-secondary, .btn-secondary {
    background-color: $secondary-color-ev !important;
    color: $white-color-ev !important;
}

.bg-dark-secondary, .btn-dark-secondary {
    background-color: $secondary-color-ev;
    color: $white-color-ev;
}

.bg-accent, .btn-accent {
    background-color: $accent-color-ev;
    color: $white-color-ev;
}

.bg-dark-accent, .btn-dark-accent {
    background-color: $dark-accent-color-ev;
    color: $white-color-ev;
}