section.owl_carousel {
    width: 100%;
    height: auto;
    overflow: hidden;

    .item {
        background-color: $accent-color-ev;
        height: auto;
        color: $light-gray-color-ev;
    }
}

section.parallax {
    .parallax-bg {
        background: transparent;

        .parallax-content {
            color: $light-gray-color-ev;
            z-index: 2;
            position: relative;
            background-color: rgba(0, 0, 0, 0.8);
            padding: 150px 50px;

            h1,
            p {
                margin: 20px auto;
                text-align: center;
            }
        }
    }
}




section.slick {
    width: 100%;
    background-color: $dark-gray-color-ev;
    color: $light-gray-color-ev;

    .client {
        width: 30%;
        overflow: hidden;
        padding: 20px 5px;

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
}




// Laravel Pagination
div.pagination-nav{
    .pager {
        @include display-flex(flex-end, center);
        padding: 1rem;
        margin: 0;

        li {
            span, a {
                padding: 0.5rem 1rem;
                background-color: $white-color-ev;
                border: 1px solid $light-gray-color-ev;
            }

            a:hover{
                background-color: $light-gray-color-ev;
                border: 1px solid $mid-gray-color-ev;
            }
        }
    }
}

.active-paginate-link span{
    background-color: #5cb85c !important;
    color: white !important;
    border-color: #5cb85c !important;
}




// Overwrite Datatables Style
table#datatable {
    background-color: $white-color-ev !important;

    th {
        background-color: $dark-gray-color-ev;
        color: $light-gray-color-ev;
    }

    .idCol {
        width: 50px !important;
    }

    .actionsCol {
        width: 100px !important;
    }
}

#datatable_paginate {
    .page-item {
        .page-link {
            color: $base-color-ev;
            &:focus {
                outline: none;
                border-color: lighten($base-color-ev, 20%);
                box-shadow: 0 0 2px $base-color-ev;
            }
        }

        &.active {
            .page-link {
                color: $white-color-ev;
                background-color: $base-color-ev;
                border-color: $base-color-ev;
            }
        }

        &.disabled {
            .page-link {
                color: $mid-gray-color-ev;
            }
        }
    }
}

div.dataTables_length {
    label {
        select {
            &:focus {
                outline: none;
                border-color: lighten($base-color-ev, 20%);
                box-shadow: 0 0 2px $base-color-ev;
            }

            option {
                background-color: $base-color-ev !important;
                color: $white-color-ev;
                &:hover {
                    background-color: $base-color-ev !important;
                }
                &:focus {
                    background-color: $base-color-ev !important;
                }
            }
        }
    }

}

div.dataTables_filter {
    input[type='search'] {
        &:focus {
            outline: none;
            border-color: lighten($base-color-ev, 20%);
            box-shadow: 0 0 2px $base-color-ev;
        }
    }
}
.dataTables_wrapper
div.dataTables_wrapper
div.dataTables_filter select {
    color: $white-color-ev;
    background-color: $dark-gray-color-ev;
}
