.social-share-icons {
    text-align: center;
    margin-top: 3rem;
    background-color: $white-color-ev;
    padding: 2rem 1rem;
    #social-links {
        width: 100%;
    }

    ul {

        display: flex;
        justify-content: space-around;
        align-items: flex-start;

        a {
            font-size: 1.5em;
        }
    }
}
