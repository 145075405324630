// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer-sidebar-wrapper {
    padding: 0.5rem 1rem;
}

.admin-footer {
    width: 100%;
    background-color: $dark-gray-color-ev;
    color: $light-gray-color-ev;
    text-align: center;

    .powered_by_link {
        color: $dark-accent-color-ev;
    }

}

@media screen and (min-width: 769px) {

}
