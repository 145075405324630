#admin-login-page-wrapper {
    min-height: 100vh;
    box-sizing: border-box;
    background-color: $dark-gray-color-ev;
    color: $white-color-ev;
    padding: 2rem;
}

.login-form-wrapper {
    max-width: 400px;
    margin: 5rem auto;
    .login-logo-wrap{
        width: 200px;
        margin: 2rem auto;
        overflow: hidden;
        img {
            width: 100%;
            max-width: 100%;
        }
    }
}
