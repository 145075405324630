.flex {
    @include display_flex(space-between, center);
}

.grid-2-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;
}
